//Import Modules
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotifierService } from "angular-notifier";

//Import Services
import { AuthService } from '../../services/auth/auth.service';
import { MunicipalitiesService } from '../..//services/municipalities/municipalities.service';
import { RenewalApplicationsService } from '../../services/renewal-applications/renewal-applications.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  constructor(public authService: AuthService, private router: Router, private notifier: NotifierService, private fb: FormBuilder, private http: HttpClient, private municipalitiesService: MunicipalitiesService, private renewalApplicationsService: RenewalApplicationsService, private cd: ChangeDetectorRef) {
    this.selectMunicipalityForm = new FormGroup({
      Municipality: new FormControl('', Validators.required),
      Year: new FormControl({ value: '', disabled: true }, Validators.required),
    });
  };

  public selectMunicipalityForm: FormGroup;
  public municipalities: any[];
  public years;
  public showAlert = false;

  //Sidebar config
  public config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'list-item-active',
    listBackgroundColor: "",
    fontColor: "#1D1E20",
    backgroundColor: "",
    selectedListFontColor: "#3F51B5",
    highlightOnSelect: true,
    collapseOnSelect: true,
    rtlLayout: false
  };

  //Sidebar routes
  public appitems = [
    {
      label: "General Info",
      link: "/dashboard/general-info",
      icon: "info"
    },
    {
      label: "Municipalities",
      link: "/dashboard/municipalities",
      icon: "info",
      hidden: this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'Claims'

    },
    {
      label: "Freeze Updates",
      link: "/dashboard/freeze-updates",
      icon: "update",
      hidden: this.authService.activeAccount.role == 'Manager' || this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly' || this.authService.activeAccount.role == 'Claims'
    },
    {
      label: "Generate New Year",
      link: "/dashboard/generate-year",
      icon: "edit_calendar",
      hidden: this.authService.activeAccount.role == 'Manager' || this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly' || this.authService.activeAccount.role == 'Claims'
    },
    {
      label: "Risk Managers",
      link: "/dashboard/risk-managers",
      icon: "verified_user",
      hidden: this.authService.activeAccount.role == 'Manager' || this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly' || this.authService.activeAccount.role == 'Claims'
    },
    {
      label: "Exposures",
      icon: 'assignment',
      items: [
        {
          label: "General",
          link: "/dashboard/exposures/general",
          icon: 'description'
        },
        {
          label: "Specific",
          link: "/dashboard/exposures/specific",
          icon: 'description'
        },
        {
          label: "Additional",
          link: "/dashboard/exposures/additional",
          icon: 'description'
        },
        {
          label: "Vehicle Information",
          link: "/dashboard/exposures/vehicles",
          icon: 'description'
        },
        {
          label: "Property",
          link: "/dashboard/exposures/property",
          icon: 'description'
        },
        {
          label: "Crime",
          icon: 'description',
          items: [
            {
              label: "Crime Application",
              link: "/dashboard/exposures/crime/crime-application",
              icon: 'description'
            },
            {
              label: "Position Bond Application",
              link: "/dashboard/exposures/crime/crime-position-bond-application",
              icon: 'description'
            },
            {
              label: "Position Bond Worksheet",
              link: "/dashboard/exposures/crime/crime-position-bond-worksheet",
              icon: 'description'
            },
          ]
        },
        {
          label: "Cyber Liability",
          link: "/dashboard/exposures/cyber-liability",
          icon: 'description'
        },
        {
          label: "Non-owned Aircraft Liability",
          link: "/dashboard/exposures/non-owned-aircraft-liability",
          icon: 'description'
        },
        {
          label: "Site Environmental Liability",
          link: "/dashboard/exposures/site-environmental-liability",
          icon: 'description'
        },
        {
          label: "UST Liability",
          link: "/dashboard/exposures/ust-liability",
          icon: 'description'
        }
      ],
    },
    {
      label: "Schedules",
      icon: 'calendar_today',
      hidden: this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly',
      items: [
        {
          label: "Locations Property Values",
          link: "/dashboard/schedules/locations-property-values",
          icon: 'description'
        },
        {
          label: "Employee Concentration",
          link: "/dashboard/schedules/employee-concentration",
          icon: 'description'
        },
        {
          label: "Watercraft",
          link: "/dashboard/schedules/watercraft",
          icon: 'description'
        },
        {
          label: "Fine Arts",
          link: "/dashboard/schedules/fine-arts",
          icon: 'description'
        },
        {
          label: "Equipment",
          link: "/dashboard/schedules/equipment",
          icon: 'description'
        },
        {
          label: "Payroll",
          link: "/dashboard/schedules/payroll",
          icon: 'description'
        },
        {
          label: "Vehicle",
          link: "/dashboard/schedules/vehicle",
          icon: 'description'
        }
      ]
    },
    {
      label: "Departments",
      icon: 'folder',
      link: "/dashboard/departments",
      hidden: this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly' || this.authService.activeAccount.role == 'Claims'
    },
    {
      label: "Reports",
      icon: 'bar_chart',
      link: "/dashboard/reports"
    },
    {
      label: "Uploads",
      icon: 'upload',
      link: "/dashboard/uploads",
      hidden: this.authService.activeAccount.role == 'Manager' || this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly' || this.authService.activeAccount.role == 'Claims'
    },
    {
      label: "Users",
      link: "/dashboard/users",
      icon: 'group',
      hidden: this.authService.activeAccount.role == 'Manager' || this.authService.activeAccount.role == 'Member' || this.authService.activeAccount.role == 'ReadOnly' || this.authService.activeAccount.role == 'Claims'
    }
  ];

  ngOnInit() {
    this.getListMunicipalitiesService();
  };

  //Get municipalities, populates the dropdown field
  async getListMunicipalitiesService() {
    this.municipalities = await this.municipalitiesService.getListMunicipalities(this.authService.activeAccount.userID).toPromise();
    if (this.municipalities) {
      this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
        if (!response) {
          this.municipalitiesService.getListMunicipalitiesYears(this.municipalities[0].municipalityID).subscribe((years: any) => {
            if (years.length != 0) {
              this.years = years;
              this.selectMunicipalityForm.controls['Year'].enable();
              this.selectMunicipalityForm.patchValue({ 'Municipality': this.municipalities[0].municipalityID, 'Year': years[0] });
              this.submitMunicipalityForm(this.selectMunicipalityForm);
            }
          });
        } else {
          this.getListMunicipalitiesYears(response.municipalityID);
          this.selectMunicipalityForm.patchValue({ 'Municipality': response.municipalityID, 'Year': response.municipalityYear });
        }
      });
    }
    this.cd.detectChanges();
  };

  /* Municipalities Select Filter */
  onKey(value) {
    this.municipalities = this.search(value);
  }
  search(value: string) {
    let filter = value.toLocaleLowerCase();
    //console.log(this.municipalities);
    return this.municipalities.filter((municipality: any) =>
      municipality.fullName.toLocaleLowerCase().startsWith(filter)
    );
  }

  //Get years based on selected "Municipality"
  getListMunicipalitiesYears(municipality) {
    this.municipalitiesService.getListMunicipalitiesYears(municipality).subscribe((response: any) => {
      if (response.length != 0) {
        this.years = response;
        this.selectMunicipalityForm.controls['Year'].enable();
        if (!this.selectMunicipalityForm.controls['Year'].value) {
          this.selectMunicipalityForm.patchValue({ 'Year': this.years[0] });
          this.submitMunicipalityForm(this.selectMunicipalityForm);
        }
      }
    });
  };

  //Change municipality
  changeMunicipality(municipality) {
    this.selectMunicipalityForm.controls['Year'].reset();
    this.getListMunicipalitiesYears(municipality);
  };

  //When submitted update the active municipality
  submitMunicipalityForm(form) {
    if (form.valid) {
      this.renewalApplicationsService.setBehaviorView({ 'municipalityID': form.value.Municipality, 'municipalityYear': form.value.Year });
    }
  };
};
