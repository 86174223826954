//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-payroll-dialog',
  templateUrl: './new-payroll-dialog.component.html',
  styleUrls: ['./new-payroll-dialog.component.scss']
})
export class NewPayrollDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewPayrollDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.newPayrollForm = new FormGroup({
      PayrollID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]), //STRING
      EffectiveDate: new FormControl('', Validators.required), //Date
      ClassificationDescription: new FormControl('', Validators.maxLength(250)), //STRING
      ClassCode: new FormControl('', Validators.maxLength(250)), //STRING
      FullYear: new FormControl('', Validators.required), //Date
      FiscalYear: new FormControl(0), //INT
      Payroll: new FormControl(0), //INT
      FullTime: new FormControl(0), //INT
      PartTime: new FormControl(0), //INT
      Seasonal: new FormControl(0), //INT
      Volunteer: new FormControl(0), //INT
      UserId: new FormControl('', [Validators.required, Validators.maxLength(128)]) //STRING
    });
  };

  public newPayrollForm: FormGroup;

  ngOnInit() {
    this.newPayrollForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear,
      'UserId': this.data.UserId
    });
  };

  //Close modal and pass form data to controller
  submitNewPayrollForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
