//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-update-pilot-dialog',
  templateUrl: './update-pilot-dialog.component.html',
  styleUrls: ['./update-pilot-dialog.component.scss']
})

export class UpdatePilotDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UpdatePilotDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.updatePilotForm = new FormGroup({
      QuestionnairePilot_yearId: new FormControl(0), //INT
      QuestionnairePilotID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      NameofPilot: new FormControl('', [Validators.maxLength(50)]), //STRING
      DOB: new FormControl('', [Validators.maxLength(15)]), //STRING
      Stud: new FormControl(false), //BOOL
      Pvt: new FormControl(false), //BOOL
      Coml: new FormControl(false), //BOOL
      ASEL: new FormControl(false), //BOOL
      AMEL: new FormControl(false), //BOOL
      Instrumt: new FormControl(false), //BOOL
      ATP: new FormControl(false), //BOOL
      Other: new FormControl('', Validators.maxLength(50)), //STRING
      DateLastPhysical: new FormControl('', Validators.maxLength(15)), //STRING
      Class: new FormControl('', Validators.maxLength(50)), //STRING
      Total: new FormControl(0), //INT
      RetractGear: new FormControl('', Validators.maxLength(50)), //STRING
      MultiEngine: new FormControl('', Validators.maxLength(50)), //STRING
      Last90days: new FormControl(0), //INT
      Last12Mos: new FormControl(0), //INT
      Last12MosPilot: new FormControl('', Validators.maxLength(50)), //STRING
      Next12MosPilot: new FormControl('', Validators.maxLength(50)), //STRING
      FAACrtNoPilot: new FormControl('', Validators.maxLength(50)), //STRING
      DateLastBiennialRev: new FormControl('', Validators.maxLength(15)), //STRING
      UserId: new FormControl('', Validators.maxLength(128)), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public updatePilotForm: FormGroup;

  ngOnInit() {
    this.updatePilotForm.patchValue({
      'MunicipalityID': this.data.municipalityID,
      'MunicipalityYear': this.data.municipalityYear,
      'QuestionnairePilotID': this.data.questionnairePilotID,
      'QuestionnairePilot_yearId': this.data.questionnairePilot_yearId,
      'NameofPilot': this.data.nameofPilot,
      'DOB': this.data.dob,
      'Stud': this.data.stud,
      'Pvt': this.data.pvt,
      'Coml': this.data.coml,
      'ASEL': this.data.aSEL,
      'AMEL': this.data.aMEL,
      'Instrumt': this.data.instrumt,
      'ATP': this.data.atp,
      'Other': this.data.other,
      'DateLastPhysical': this.data.dateLastPhysical,
      'Class': this.data.class,
      'Total': this.data.total,
      'RetractGear': this.data.retractGear,
      'MultiEngine': this.data.multiEngine,
      'Last90days': this.data.last90days,
      'Last12MosPilot': this.data.last12MosPilot,
      'Last12Mos': this.data.last12Mos,
      'Next12MosPilot': this.data.next12MosPilot,
      'FAACrtNoPilot': this.data.faaCrtNoPilot,
      'DateLastBiennialRev': this.data.dateLastBiennialRev,
      'UserId': this.data.userId,
      'EffectiveDate': this.data.effectiveDate
    });
  };

  //Close modal and pass form data to controller
  submitUpdatePilotForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
