//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-update-fine-art-dialog',
  templateUrl: './update-fine-art-dialog.component.html',
  styleUrls: ['./update-fine-art-dialog.component.scss']
})
export class UpdateFineArtDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UpdateFineArtDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.updateFineArtForm = new FormGroup({
      ScheduleFineArts_yearId: new FormControl(0), //INT
      FineArtsID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      Location: new FormControl('', Validators.maxLength(150)), //STRING
      Year: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      Artist: new FormControl('', Validators.maxLength(150)), //STRING
      Description: new FormControl('', [Validators.required, Validators.maxLength(100)]), //STRING
      AppraisedValue: new FormControl(0), //INT
      UserId: new FormControl('', Validators.maxLength(128)), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public updateFineArtForm: FormGroup;

  ngOnInit(): void {
    this.updateFineArtForm.patchValue({
      'ScheduleFineArts_yearId': this.data.scheduleFineArts_yearId,
      'FineArtsID': this.data.fineArtsID,
      'MunicipalityID': this.data.municipalityID,
      'MunicipalityYear': this.data.municipalityYear,
      'Location': this.data.location,
      'Year': this.data.year,
      'Artist': this.data.artist,
      'Description': this.data.description,
      'AppraisedValue': this.data.appraisedValue,
      'EntryDate': this.data.entryDate,
      'UserId': this.data.userId,
      'EffectiveDate': this.data.effectiveDate
    });
  };

  //Close modal and pass form data to controller && Replace dialogRef form with ID
  submitUpdateFineArtForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
