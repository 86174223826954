//Import Modules
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { FormValidator } from '../../../../shared/pipes/FormValidator';

@Component({
  selector: 'app-add-new-risk-manager-dialog',
  templateUrl: './add-new-risk-manager-dialog.component.html',
  styleUrls: ['./add-new-risk-manager-dialog.component.scss']
})
export class AddNewRiskManagerDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddNewRiskManagerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.newRiskManagerForm = new FormGroup({
      RiskManagerID: new FormControl(0, Validators.required),
      Sex: new FormControl('', [Validators.maxLength(1)]),
      FirstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      LastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      Company: new FormControl('', [Validators.maxLength(250)]),
      Address1: new FormControl('', [Validators.maxLength(100)]),
      Address2: new FormControl('', [Validators.maxLength(100)]),
      City: new FormControl('', [Validators.maxLength(50)]),
      State: new FormControl('', Validators.compose([Validators.minLength(2), Validators.maxLength(2), Validators.required, FormValidator.patternValidator(/^[A-Z]/, { isCapital: true })])), //STRING
      Zip: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(5), Validators.required, FormValidator.patternValidator(/^[0-9]*$/, { isNumbersOnly: true })])) //STRING
    });
  };

  public newRiskManagerForm: FormGroup;

  ngOnInit() { };

  //Close modal and pass form data to controller
  submitNewRiskManagerForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };
};
