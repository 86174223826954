//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private http: HttpClient) { }

  private endpoint = '/API/Upload/UploadFile';

  public uploadData(data) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');

    let formData: FormData = new FormData();
    formData.append('File', data.File);
    formData.append('MunicipalityID', data.MunicipalityID);
    formData.append('MunicipalityYear', data.MunicipalityYear);
    formData.append('UserId', data.UserId);
    formData.append('UploadTable', data.UploadTable);

    return this.http.post(this.endpoint, formData, { headers: headers });
  }
};

//Test
//const headers = new HttpHeaders({
//  'Content-Type': 'multipart/form-data',
//  'Accept': 'application/json'
//});
