//Import Modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {
  constructor(private http: HttpClient) { }

  private endpoint = '/API/Schedules/'

  getList(municipalityID, municipalityYear, route): Observable<any> {
    return this.http.get(this.endpoint + route, { params: { municipalityID, municipalityYear } });
  };

  getDetail(municipalityID, route): Observable<any> {
    return this.http.get(this.endpoint + route, { params: { id: municipalityID } });
  };

  add(data, route): Observable<any> {
    return this.http.post(this.endpoint + route, data);
  };

  update(data, route): Observable<any> {
    return this.http.put(this.endpoint + route, data)
  };

  delete(municipalityID, route): Observable<any> {
    return this.http.delete(this.endpoint + route, { params: { id: municipalityID } });
  };
};
