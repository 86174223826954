//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-employee-location-dialog',
  templateUrl: './new-employee-location-dialog.component.html',
  styleUrls: ['./new-employee-location-dialog.component.scss']
})
export class NewEmployeeLocationDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewEmployeeLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.newEmployeeLocationForm = new FormGroup({
      EmployeeLocations_yearId: new FormControl(0), //INT
      EmployeeLocationID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //INT
      NumberOfEmployees: new FormControl(null, Validators.required), //INT
      EmployeesPerFloor: new FormControl(null, Validators.required), //INT
      Shift1Employees: new FormControl(null, Validators.required), //INT
      Shift2Employees: new FormControl(null, Validators.required), //INT
      Shift3Employees: new FormControl(null, Validators.required), //INT
      PropLocation: new FormControl('', [Validators.required, Validators.maxLength(150)]), //String
      HoursOfOperation: new FormControl('', Validators.maxLength(50)), //String
      FloorsOccupied: new FormControl('', Validators.maxLength(50)), //String
      BuildingConstruction: new FormControl('', Validators.maxLength(50)), //String
      NumberOfStories: new FormControl(0), //INT
      YearBuilt: new FormControl(0), //INT
      EffectiveDate: new FormControl('', Validators.required), //DATE
      UserId: new FormControl([Validators.required, Validators.maxLength(128)]), //STRING
    });
  };

  public newEmployeeLocationForm: FormGroup;

  ngOnInit(): void {
    this.newEmployeeLocationForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear,
      'UserId': this.data.UserId
    });
  };

  //Close modal and pass form data to controller
  submitNewEmployeeForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
