//Import Modules
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from "angular-notifier";

//Import Services
import { SchedulesService } from '../../../shared/services/schedules/schedules.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

//Import Components
import { NewPayrollDialogComponent } from './dialog/new-payroll-dialog/new-payroll-dialog.component';
import { UpdatePayrollDialogComponent } from './dialog/update-payroll-dialog/update-payroll-dialog.component';
import { DeletePayrollDialogComponent } from './dialog/delete-payroll-dialog/delete-payroll-dialog.component';

//Export to Excel
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})

export class PayrollComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private schedulesService: SchedulesService, private authService: AuthService, private notifier: NotifierService) { }
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public activeMunicipality;
  public sort;

  //Payroll table
  public displayedColumns: string[] = ["classificationDescription", "classCode", "payroll", "fullTime", "partTime", "seasonal", "volunteer", "action"];
  public data;
  public dataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        console.log(response);
        this.activeMunicipality = response;
        this.getTableList(response);
      }
    });
  };

  public getTableList(data) {
    this.schedulesService.getList(data.municipalityID, data.municipalityYear, 'GetListPayrolls').subscribe((response: any) => {
      if (!response) {
        return
      }
      else {
        this.data = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
      }
    });
  };

  public getDetails(employeeConcentrationID) {
    this.schedulesService.getDetail(employeeConcentrationID, 'GetDetailEmployeeConcentrations').subscribe((response: any) => {
      console.log(response);
    });
  };

  public openNewDialog() {
    const dialogRef = this.dialog.open(NewPayrollDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear,
        'UserId': this.authService.activeAccount.userID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.add(result, 'AddPayroll').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully created new record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public openUpdateDialog(data) {
    const dialogRef = this.dialog.open(UpdatePayrollDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.update(result, 'UpdatePayroll').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully updated existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public openDeleteDialog(data) {
    const dialogRef = this.dialog.open(DeletePayrollDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.delete(result, 'DeletePayroll').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  }

  public calculateTotalPayroll() {
    return this.dataSource.filteredData.map(t => t.payroll).reduce((acc, value) => acc + value, 0);
  }

  public calculateFullTime() {
    return this.dataSource.filteredData.map(t => t.fullTime).reduce((acc, value) => acc + value, 0);
  }

  public calculatePartTime() {
    return this.dataSource.filteredData.map(t => t.partTime).reduce((acc, value) => acc + value, 0);
  }

  public calculateSeasonal() {
    return this.dataSource.filteredData.map(t => t.seasonal).reduce((acc, value) => acc + value, 0);
  }

  public calculateVolunteer() {
    return this.dataSource.filteredData.map(t => t.volunteer).reduce((acc, value) => acc + value, 0);
  }

  public filterTable = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportExcel() {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Payroll');

    worksheet.columns = [
      { header: 'Description', key: 'classificationDescription', width: 62 },
      { header: 'Class Code', key: 'classCode', width: 12 },
      { header: 'Payroll', key: 'payroll', width: 18 },
      { header: 'Full Time', key: 'fullTime', width: 12 },
      { header: 'Part Time', key: 'partTime', width: 12 },
      { header: 'Seasonal', key: 'seasonal', width: 12 },
      { header: 'Volunteer', key: 'volunteer', width: 12 },
    ];

    worksheet.getColumn(3).numFmt = '"$"#,##0.00';


    this.data.forEach(e => {
      worksheet.addRow({
        classificationDescription: e.classificationDescription,
        classCode: e.classCode,
        payroll: e.payroll,
        fullTime: e.fullTime,
        partTime: e.partTime,
        seasonal: e.seasonal,
        volunteer: e.volunteer
      }, "n");
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Payroll.xlsx');
    })

  }
}
