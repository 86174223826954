<div class="storage-tank-inventory-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <form [formGroup]="storageTankInventoryForm" (ngSubmit)="submitForm(storageTankInventoryForm)">
      <div class="row">
        <div class="col-md-12">
          <!--Facility Name-->
          <mat-label>Select Facility Name:</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select (selectionChange)="changeActiveTankLocation($event.value)" [disabled]="!activeMunicipality">
              <mat-option *ngFor="let tankLocation of availableStorageTanks" [value]="tankLocation">{{tankLocation.facilityName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="action-buttons">
            <button mat-raised-button color="primary" type="button" (click)="openNewStorageTankLocationDialog()" [disabled]="!activeMunicipality">Add new location</button>
            <button class="ml-1" mat-raised-button color="primary" type="button" (click)="openUpdateStorageTankLocationDialog(storageTankInventoryForm.get('StorageTankInventoryDetails_yearId').value)" [disabled]="storageTankInventoryForm.get('StorageTankInventoryDetails_yearId').value == 0">Edit location</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--FacilityName-->
          <mat-label>Facility Name</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FacilityName" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--FacilityAddress-->
          <mat-label>Facility Address</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FacilityAddress" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <!--FacilityId-->
          <mat-label>Facility ID</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FacilityId" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!--EffectiveDate -->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is requried</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5>(Complete schedule with symbols below)</h5>
        </div>
      </div>
      <div class="table-form-layout-header bg-light p-2 border-top border-right border-left">
        <div class="row">
          <div class="col-md-1">
            <div class="col-name">
              <span>Label</span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="col-name">
              <span>Tank # 1</span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="col-name">
              <span>Tank # 2</span>
            </div>
          </div>
          <div class="col-md-2">
            <span>Tank # 3</span>
          </div>
          <div class="col-md-2">
            <div class="col-name">
              <span>Tank # 4</span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="col-name">
              <span>Tank # 5</span>
            </div>
          </div>
        </div>
      </div>
      <div class="table-form-layout-body border">
        <div class="row">
          <div class="col-md-12">
            <!--Tank #-->
            <div class="tank-number-wrapper mt-3">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Tank #</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <input matInput formControlName="TankNo_{{i + 1}}" placeholder="TankNo_{{i + 1}}" />
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--UST/AST-->
            <div class="ust-ast-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">UST/AST</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <input matInput formControlName="UST_AST{{i + 1}}" placeholder="UST_AST{{i + 1}}" />
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--InstallDateYear-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Install Date Year</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <input matInput formControlName="InstallDateYear{{i + 1}}" placeholder="UST_AST{{i + 1}}" />
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--Capacity-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Capacity</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <input matInput formControlName="Capacity{{i + 1}}" placeholder="Capacity{{i + 1}}" />
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--Contents-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Contents</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <mat-select formControlName="Contents{{i + 1}}" placeholder="Select">
                      <mat-option value="A">A. Unleaded Gasoline</mat-option>
                      <mat-option value="B">B. Gasohol</mat-option>
                      <mat-option value="C">C. Diesel</mat-option>
                      <mat-option value="D">D. Kerosene</mat-option>
                      <mat-option value="E">E. Waste Oil/ Used Oil</mat-option>
                      <mat-option value="F">F. Fuel Oil</mat-option>
                      <mat-option value="G">G. Generic Gasoline</mat-option>
                      <mat-option value="H">H. Pesticide</mat-option>
                      <mat-option value="I">I. Ammonia compound</mat-option>
                      <mat-option value="J">J. Chlorine compound</mat-option>
                      <mat-option value="K">K. Haz. Substance (CERCLA)</mat-option>
                      <mat-option value="L">L. Mineral Acids</mat-option>
                      <mat-option value="M">M. Grades 5&amp;amp;6 bunker 'C' oils</mat-option>
                      <mat-option value="N">N. Petroleum-base  additive(E85)</mat-option>
                      <mat-option value="O">O. Misc. petroleum-base</mat-option>
                      <mat-option value="P">P.Heating Oil</mat-option>
                      <mat-option value="Qs">Q.Other</mat-option>
                    </mat-select>
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--Tank Construction Material-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Tank Construction Material</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <mat-select formControlName="TankConstructionMaterial{{i + 1}}" placeholder="Select">
                      <mat-option value="A">A. Steel</mat-option>
                      <mat-option value="B">B. Fiberglass</mat-option>
                      <mat-option value="C">C. FRP Clad Steel</mat-option>
                      <mat-option value="D">D. Concrete</mat-option>
                      <mat-option value="E">E. Polyethylene</mat-option>
                      <mat-option value="F">F. Other EPA/DEP Approved</mat-option>
                      <mat-option value="G">G. Cathodic Protection Sacrificial Anode</mat-option>
                      <mat-option value="H">H. Cathodic Protection -Impressed Current</mat-option>
                      <mat-option value="I">I.  Double Walled(DW)-Single Material</mat-option>
                      <mat-option value="J">J. Double Walled (DW)-Dual Material</mat-option>
                      <mat-option value="K">K. (DW)Synthetic Liner in Tank Construction</mat-option>
                      <mat-option value="L">L. (DW)Pipeless UST with Secondary Containment</mat-option>
                      <mat-option value="M">M. Internal Lining STI. STI-P3</mat-option>
                    </mat-select>
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--Overfill/Spill Protection-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Overfill/Spill Protection</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <mat-select formControlName="OverfillSpillProtection{{i + 1}}" placeholder="Select">
                      <mat-option value="A">A. Ball Check Valve</mat-option>
                      <mat-option value="B">B. Spill Containment Bucket</mat-option>
                      <mat-option value="C">C. Flow Shut-off</mat-option>
                      <mat-option value="D">D. Tight Fill</mat-option>
                      <mat-option value="E">E. Level Gauges, High Level Alarms</mat-option>
                      <mat-option value="F">F. Other EPA/DEP Approved Protection Method</mat-option>
                    </mat-select>
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--Tank Leak Detection-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Tank Leak Detection</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <mat-select formControlName="TankLeakDetection{{i + 1}}" placeholder="Select">
                      <mat-option value="A">A. Groundwater Monitoring Wells</mat-option>
                      <mat-option value="B">B. Interstitial Monitoring</mat-option>
                      <mat-option value="C">C. Vapor Monitoring Wells</mat-option>
                      <mat-option value="D">D. Visual Inspections of AST Systems</mat-option>
                      <mat-option value="E">E. Other EPA/DEP Approved</mat-option>
                      <mat-option value="F">F. SPCC Plan - AST</mat-option>
                      <mat-option value="G">G. Interstitial Space -Double Walled Tank</mat-option>
                      <mat-option value="H">H. Manual Tank Gauging - UST</mat-option>
                      <mat-option value="I">I. Statistical Inventory Reconciliation-(SIR)(USTs)</mat-option>
                      <mat-option value="J">J. Automatic Tank Gauging System (USTs)</mat-option>
                      <mat-option value="K">K. Interstitial Monitoring of AST tank bottom</mat-option>
                      <mat-option value="L">L. Annual Tightness Test with Inventory-(USTs)</mat-option>
                      <mat-option value="M">M. Grades 5&amp;amp;6 bunker 'C' oils</mat-option>
                      <mat-option value="N">N. Petroleum-base  additive(E85)</mat-option>
                      <mat-option value="O">O. Misc. petroleum-base</mat-option>
                    </mat-select>
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--AST Diking & Base Construction-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">AST Diking & Base Construction</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <mat-select formControlName="ASTDiking{{i +1}}" placeholder="Select">
                      <mat-option value="A">A. Concrete, Synthetic Material, clays</mat-option>
                      <mat-option value="B">B. Other EPA/DEP approved secondary containment system</mat-option>
                      <mat-option value="C">C. Dirt/Earth</mat-option>
                    </mat-select>
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--Piping Construction Material-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Piping Construction Material</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <mat-select formControlName="PipingConstruction{{i + 1}}" placeholder="Select">
                      <mat-option value="A">A. Steel</mat-option>
                      <mat-option value="B">B. Fiberglass</mat-option>
                      <mat-option value="C">C. Double walled</mat-option>
                      <mat-option value="D">D. Approved Synthetic Material</mat-option>
                      <mat-option value="E">E. Other EPA/DEP Approved Piping Material</mat-option>
                      <mat-option value="F">F. External Protective Coating</mat-option>
                      <mat-option value="G">G. C/P with sacrificial anode or impressed current</mat-option>
                    </mat-select>
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--Piping Leak Detection-->
            <div class="install-date-year-wrapper">
              <div class="row">
                <div class="col-md-1 text-center align-self-center">
                  <span class="label">Piping Leak Detection</span>
                </div>
                <div class="col-md-2" *ngFor="let repeater of [].constructor(5); let i = index">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <mat-select formControlName="PipingLeakDetection{{i + 1}}" placeholder="Select">
                      <mat-option value="A">A. Electronic Line Leak Detector with Flow Shutoff</mat-option>
                      <mat-option value="B">B. Interstitial Monitoring – Piping Filter</mat-option>
                      <mat-option value="C">C. External Monitoring</mat-option>
                      <mat-option value="D">D. Mechanical Line Leak Detector</mat-option>
                      <mat-option value="E">E. Interstitial Monitoring of double wall piping</mat-option>
                      <mat-option value="F">F. Suction Pump Check Valve</mat-option>
                    </mat-select>
                    <mat-error>Character length for this field is 50</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="storageTankInventoryForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
