//Import Modules
import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from "angular-notifier";

//Import Services
import { SchedulesService } from '../../../shared/services/schedules/schedules.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

//Import Components
import { NewEquipmentDialogComponent } from './dialog/new-equipment-dialog/new-equipment-dialog.component';
import { UpdateEquipmentDialogComponent } from './dialog/update-equipment-dialog/update-equipment-dialog.component';
import { DeleteEquipmentDialogComponent } from './dialog/delete-equipment-dialog/delete-equipment-dialog.component';

//Export to Excel
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private schedulesService: SchedulesService, private authService: AuthService, private notifier: NotifierService) { }
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public activeMunicipality;
  public sort;

  //Equipment table
  public displayedColumns: string[] = ["departmentName", "equipYear", "model", "equipNumber", "serialNumber", "insuranceLimit", "action"];
  public data;
  public dataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.activeMunicipality = response;
        this.getTableList(response);
      }
    });
  };

  public getTableList(data) {
    this.schedulesService.getList(data.municipalityID, data.municipalityYear, 'GetListEquipment').subscribe((response: any) => {
      if (!response) {
        return
      }
      else {
        this.data = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
      }
    });
  };

  public getDetails(employeeConcentrationID) {
    this.schedulesService.getDetail(employeeConcentrationID, 'GetDetailEmployeeConcentrations').subscribe((response: any) => {
      console.log(response);
    });
  };

  public openNewDialog() {
    const dialogRef = this.dialog.open(NewEquipmentDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear,
        'UserId': this.authService.activeAccount.userID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.add(result, 'AddEquipment').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully created new record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public openUpdateDialog(data) {
    const dialogRef = this.dialog.open(UpdateEquipmentDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.update(result, 'UpdateEquipment').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully updated existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public openDeleteDialog(data) {
    const dialogRef = this.dialog.open(DeleteEquipmentDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.delete(result, 'DeleteEquipment').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public calculateInsuranceLimit() {
    return this.dataSource.filteredData.map(t => t.insuranceLimit).reduce((acc, value) => acc + value, 0);
  }

  public filterTable = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportExcel() {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Equipments');

    worksheet.columns = [
      { header: 'Department', key: 'departmentName', width: 26 },
      { header: 'Year', key: 'equipYear', width: 12 },
      { header: 'Model', key: 'model', width: 32 },
      { header: 'Number', key: 'equipNumber', width: 16 },
      { header: 'Serial #', key: 'serialNumber', width: 20 },
      { header: 'Insurance Limit', key: 'insuranceLimit', width: 20 },
    ];

    worksheet.getColumn(6).numFmt = '"$"#,##0.00';

    this.data.forEach(e => {
      worksheet.addRow({
        departmentName: e.departmentName,
        equipYear: e.equipYear,
        model: e.model,
        equipNumber: e.equipNumber,
        serialNumber: e.serialNumber,
        insuranceLimit: e.insuranceLimit
      }, "n");
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Equipments.xlsx');
    })

  }

}
