//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from "angular-notifier";
import { MunicipalitiesService } from '../../../../../shared/services/municipalities/municipalities.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-new-watercraft-dialog',
  templateUrl: './new-watercraft-dialog.component.html',
  styleUrls: ['./new-watercraft-dialog.component.scss']
})
export class NewWatercraftDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewWatercraftDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private municipalitiesService: MunicipalitiesService, private notifier: NotifierService) {
    this.addWatercraftForm = new FormGroup({
      ScheduleWatercraft_yearId: new FormControl(0), //INT
      WatercraftID: new FormControl(0), //INT
      DepartmentID: new FormControl(0, Validators.required), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      Year: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]), //STRING
      Make: new FormControl('', [Validators.required, Validators.maxLength(150)]), //STRING
      Model: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      Description: new FormControl('', Validators.maxLength(100)), //STRING
      VIN: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      CNV: new FormControl(0), //INT
      UserId: new FormControl('', [Validators.required, Validators.maxLength(128)]), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public departments;
  public addWatercraftForm: FormGroup;

  ngOnInit() {
    this.getDepartmentsMunicipalitiesService();
    this.addWatercraftForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear,
      'UserId': this.data.UserId
    });
  };

  //Get department id, populates the dropdown field
  getDepartmentsMunicipalitiesService() {
    this.municipalitiesService.getDepartments().pipe(first()).subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: error => {
        this.notifier.notify('error', 'Something went wrong');
      }
    });
  };

  //Close modal and pass form data to controller
  submitAddWatercraftForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
