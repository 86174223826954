//Import Modules
import { Component, ChangeDetectorRef, OnInit, ViewChild, AfterViewInit, AfterViewChecked, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from "angular-notifier";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from "rxjs/operators";

//Import Components
import { AddNewMunipalityComponent } from '../munipalities/dialog/add-new-munipality/add-new-munipality.component';
import { EditMunipalityComponent } from '../munipalities/dialog/edit-munipality/edit-munipality.component';

//Import Services
import { MunicipalitiesService } from '../../shared/services/municipalities/municipalities.service';
import { GenerateNewYearService } from "../../shared/services/generate-new-year/generate-new-year.service";
import { AuthService } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'app-munipalities',
  templateUrl: './munipalities.component.html',
  styleUrls: ['./munipalities.component.scss']
})
export class MunipalitiesComponent implements OnInit {
  constructor(private notifier: NotifierService, public dialog: MatDialog, private generateNewYearService: GenerateNewYearService, private municipalitiesService: MunicipalitiesService, public authService: AuthService) { };
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  ngOnInit() {
    this.getGenerateMunicipalitiesWithYears();
  };

  public sort;
  public dataSource;
  public displayedColumns: string[] = ['fullName', 'Years'];

  //Get table data
  public getGenerateMunicipalitiesWithYears() {
    this.generateNewYearService.getMunicipalitiesWithYears().subscribe((response: any) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.sort = this.sort;
    });
  };

  //Refresh table data
  public refreshGenerateMunicipalitiesWithYears() {
    this.generateNewYearService.getMunicipalitiesWithYears().subscribe((response: any) => {
      this.dataSource.data = response;
      console.log('refresh: ', response);
    });
  };

  public openNewMunicipalityDialog() {
    const dialogRef = this.dialog.open(AddNewMunipalityComponent, {});

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.municipalitiesService.addMunicipality(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.refreshGenerateMunicipalitiesWithYears();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        })
      } else {
        this.refreshGenerateMunicipalitiesWithYears();
        console.log('The dialog was closed');
      }

    });
  };

  public openEditMunicipalityDialog(id, year) {
    const dialogRef = this.dialog.open(EditMunipalityComponent, {
      data: { id: id, selectedYear: year }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.municipalitiesService.updateMunicipality(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.refreshGenerateMunicipalitiesWithYears();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        })
      } else {
        console.log('The dialog was closed');
      }
    });
  };
};
