//Import Modules
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { NotifierService } from "angular-notifier";

//Import Components
import { CreateDepartmentComponent } from '../departments/create-department/create-department.component';
import { EditDepartmentComponent } from '../departments/edit-department/edit-department.component';
import { DeleteDepartmentComponent } from '../departments/delete-department/delete-department.component';

//Import Services
import { DepartmentsService } from '../departments/services/departments.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  constructor(private departmentsService: DepartmentsService, private dialog: MatDialog, private notifier: NotifierService) { }

  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public sort;
  public displayedColumns: string[] = ['departmentID', 'department', 'action'];
  public dataSource;

  ngOnInit() {
    this.getAllDepartments();
  };

  //Get all users and poplulate the table
  getAllDepartments() {
    this.departmentsService.getAllDepartments().subscribe((response: any) => {
      this.dataSource = new MatTableDataSource(response);
    });
  };

  //Open register new account dialog
  openAddDialog() {
    const dialogRef = this.dialog.open(CreateDepartmentComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.departmentsService.departmentCreate(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.getAllDepartments();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  //Open edit account dialog
  openEditDialog(data) {
    const dialogRef = this.dialog.open(EditDepartmentComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.departmentsService.departmentEdit(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.getAllDepartments();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        })
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  //Open delete account dialog
  openDeleteDialog(data) {
    const dialogRef = this.dialog.open(DeleteDepartmentComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.departmentsService.departmentDelete(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.getAllDepartments();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        })
      } else {
        console.log('The dialog was closed');
      }
    });
  };

}
