<div class="watercraft-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Watercraft</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" [disabled]="!activeMunicipality" (click)="openNewDialog()">Add New Watercraft</button>
          <button mat-raised-button type="button" color="primary" class="mb-2 ml-2" [disabled]="!activeMunicipality" (click)="exportExcel()">Export to Excel</button>
        </div>
      </div>
      <!-- Filter -->
      <div class="col-md-4">
        <mat-form-field class="field-full-width">
          <input matInput type="text" (keyup)="filterTable($event.target.value)" placeholder="Filter results">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        <table class="datatable" mat-table [dataSource]="dataSource" matSort matSortActive="make" matSortDirection="asc" [@.disabled]="true">
          <!-- Department -->
          <ng-container matColumnDef="departmentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
            <td mat-cell *matCellDef="let element"> {{element.departmentName}} </td>
          </ng-container>
          <!-- Year -->
          <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Year </th>
            <td mat-cell *matCellDef="let element"> {{element.year}} </td>
          </ng-container>
          <!-- Make -->
          <ng-container matColumnDef="make">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Make </th>
            <td mat-cell *matCellDef="let element"> {{element.make}} </td>
          </ng-container>
          <!-- model -->
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> model </th>
            <td mat-cell *matCellDef="let element"> {{element.model}} </td>
          </ng-container>
          <!-- Description -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
          <!-- VIN -->
          <ng-container matColumnDef="vin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> VIN </th>
            <td mat-cell *matCellDef="let element"> {{element.vin}} </td>
          </ng-container>
          <!-- CNV -->
          <ng-container matColumnDef="cnv">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CNV </th>
            <td mat-cell *matCellDef="let element"> {{element.cnv}} </td>
          </ng-container>
          <!-- Action -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openUpdateDialog(element)">mode_edit</mat-icon>
              <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteDialog(element)">delete</mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>


