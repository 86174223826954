//Import Modules
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { NotifierService } from "angular-notifier";

//Import Services
import { SchedulesService } from '../../../shared/services/schedules/schedules.service';
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';

//Import Components
import { NewFineArtsDialogComponent } from './dialog/new-fine-arts-dialog/new-fine-arts-dialog.component';
import { UpdateFineArtDialogComponent } from './dialog/update-fine-art-dialog/update-fine-art-dialog.component';
import { DeleteFineArtDialogComponent } from './dialog/delete-fine-art-dialog/delete-fine-art-dialog.component';

//Export to Excel
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-fine-arts',
  templateUrl: './fine-arts.component.html',
  styleUrls: ['./fine-arts.component.scss']
})

export class FineArtsComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private schedulesService: SchedulesService, private authService: AuthService, private notifier: NotifierService) { }
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  public activeMunicipality;
  public sort;

  //Fine arts table
  public displayedColumns: string[] = ["location", "year", "artist", "description", "appraisedValue", "action"];
  public data;
  public dataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.activeMunicipality = response;
        this.getTableList(response);
      }
    });
  };

  public getTableList(data) {
    this.schedulesService.getList(data.municipalityID, data.municipalityYear, 'GetListFineArts').subscribe((response: any) => {
      if (!response) {
        return
      }
      else {
        this.data = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.sort = this.sort;
      }
    });
  };

  public getDetails(employeeConcentrationID) {
    this.schedulesService.getDetail(employeeConcentrationID, 'GetDetailEmployeeConcentrations').subscribe((response: any) => {
      console.log(response);
    });
  };

  public openNewDialog() {
    const dialogRef = this.dialog.open(NewFineArtsDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear,
        'UserId': this.authService.activeAccount.userID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.add(result, 'AddFineArt').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully created new record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public openUpdateDialog(data) {
    const dialogRef = this.dialog.open(UpdateFineArtDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.update(result, 'UpdateFineArt').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully updated existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public openDeleteDialog(data) {
    const dialogRef = this.dialog.open(DeleteFineArtDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulesService.delete(result, 'DeleteFineArt').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public calculateAppraisedValue() {
    return this.dataSource.filteredData.map(t => t.appraisedValue).reduce((acc, value) => acc + value, 0);
  }

  public filterTable = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  exportExcel() {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Fine Arts');

    worksheet.columns = [
      { header: 'Location', key: 'location', width: 30 },
      { header: 'Year', key: 'year', width: 12 },
      { header: 'Artist', key: 'artist', width: 22 },
      { header: 'Description', key: 'description', width: 48 },
      { header: 'Appraised Value', key: 'appraisedValue', width: 18 },
    ];

    worksheet.getColumn(5).numFmt = '"$"#,##0.00';

    this.data.forEach(e => {
      worksheet.addRow({
        location: e.location,
        year: e.year,
        artist: e.artist,
        description: e.description,
        appraisedValue: e.appraisedValue
      }, "n");
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Equipments.xlsx');
    })

  }

}
