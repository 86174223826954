//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

//Import Services
import { AuthService } from '../../shared/services/auth/auth.service';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(public authService: AuthService, private http: HttpClient, private fb: FormBuilder, private router: Router, private notifier: NotifierService) {
    this.loginForm = new FormGroup({
      Email: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required)
    });
  };

  public loginForm: FormGroup;

  ngOnInit() { }

  //Submit login form
  submitLoginForm(form) {
    if (form.valid) {
      this.authService.accountLogin(this.loginForm.value).pipe(first()).subscribe({
        next: (response: any) => {
          if (response.loginTwoStep) {
            this.router.navigateByUrl('/two-step-auth');
          } else {
            this.router.navigateByUrl('/dashboard/general-info');
          }
        },
        error: error => {
          this.notifier.notify('error', 'Wrong email or password, please try again');
        }
      });
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };
};
