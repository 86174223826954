//Imort Modules
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';

//Import Services
import { AuthService } from '../auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private xsrfTokenExtractor: HttpXsrfTokenExtractor) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // load token  
    let xsrfToken = this.xsrfTokenExtractor.getToken();

    if (xsrfToken != null) {
      // create a copy of the request and  
      // append the XSRF token to the headers list  
      const authorizedRequest = request.clone({
        withCredentials: true,
        headers: request.headers.set('X-XSRF-TOKEN', xsrfToken)
      });
      
      return next.handle(authorizedRequest);
    } else {  
      return next.handle(request);
    } 

  }
};
