import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-update-loss-experience-dialog',
  templateUrl: './update-loss-experience-dialog.component.html',
  styleUrls: ['./update-loss-experience-dialog.component.scss']
})
export class UpdateLossExperienceDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UpdateLossExperienceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.updateLossExperienceForm = new FormGroup({
      Crimeloss_yearId: new FormControl(0), //INT
      CrimeLossID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      LossDate: new FormControl('', Validators.required), //DATE
      LossDescription: new FormControl('', [Validators.required, Validators.maxLength(250)]), //STRING
      CorrectiveAction: new FormControl('', [Validators.required, Validators.maxLength(250)]), //STRING
      TotalAmount: new FormControl(0), //INT
      EffectiveDate: new FormControl('', [Validators.required, Validators.required]), //DATE
      UserId: new FormControl('', [Validators.required, Validators.maxLength(128)]) //STRING
    });
  };

  public updateLossExperienceForm: FormGroup;

  ngOnInit() {
    this.updateLossExperienceForm.patchValue({
      'Crimeloss_yearId': this.data.crimeloss_yearId,
      'CrimeLossID': this.data.crimeLossID,
      'MunicipalityID': this.data.municipalityID,
      'MunicipalityYear': this.data.municipalityYear,
      'LossDate': this.data.lossDate,
      'LossDescription': this.data.lossDescription,
      'CorrectiveAction': this.data.correctiveAction,
      'TotalAmount': this.data.totalAmount,
      'EffectiveDate': this.data.effectiveDate,
      'UserId': this.data.userId
    });
  };

  //Close modal and pass form data to controller
  submitUpdateLossExperienceForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };
};
