//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-crime-positon-bond-worksheet-dialog',
  templateUrl: './new-crime-positon-bond-worksheet-dialog.component.html',
  styleUrls: ['./new-crime-positon-bond-worksheet-dialog.component.scss']
})
export class NewCrimePositonBondWorksheetDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewCrimePositonBondWorksheetDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.crimePositionBondForm = new FormGroup({
      PositionBondProgramID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      AODate: new FormControl('', Validators.required), //DATE
      Position: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      Name: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      LimitRequired: new FormControl('', [Validators.maxLength(50)]), //STRING
      BondCopy: new FormControl(false), //BOOL
      ApplicationCopy: new FormControl(false), //BOOL
      ApplicationDate: new FormControl('', Validators.required), //DATE
      CurrentPolicyDuration: new FormControl('', [Validators.required, Validators.maxLength(50)]), //STRING
      AsOf: new FormControl('', Validators.required), //DATE
      EffectiveDate: new FormControl('', Validators.required), //DATE
      UserId: new FormControl('', [Validators.required, Validators.maxLength(128)]) //STRING
    });
  };

  ngOnInit(): void {
    this.crimePositionBondForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear,
      'UserId': this.data.UserId
    });
  };

  public crimePositionBondForm: FormGroup;

  //Close modal and pass form data to controller
  submitCrimePositionBondForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

}
