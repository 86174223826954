//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
import { first } from 'rxjs/operators';

//Import Services
import { DepartmentsService } from '../services/departments.service';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.scss']
})
export class EditDepartmentComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditDepartmentComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private departmentServices: DepartmentsService, private notifier: NotifierService) {
    this.editDepartmentForm = new FormGroup({
      DepartmentID: new FormControl('', Validators.required),
      Department: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });
  };

  public editDepartmentForm: FormGroup;

  ngOnInit() {
    this.editDepartmentForm.patchValue({
      'DepartmentID': this.data.departmentID,
      'Department': this.data.department
    });
  };

  //Submit edit account form
  submitEditDepartmentForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without changes
  onNoClick() {
    this.dialogRef.close();
  };

}
