<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Add New Storage Tank Location</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="newStorageTankLocationForm" (ngSubmit)="submitNewStorageTankLocationForm(newStorageTankLocationForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Storage Tank Location Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--CompanyName -->
            <mat-label>Company Name</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="CompanyName">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--Address -->
            <mat-label>Address</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Address">
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--FacilityType -->
            <mat-label>Facility Type</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="FacilityType">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--FacilityTypeOther -->
            <mat-label>Facility Type Other</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="FacilityTypeOther">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--USTLocation -->
            <mat-label>UST Location</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="USTLocation">
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--ASTLocation -->
            <mat-label>ASTLocation</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="ASTLocation">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--Contamination -->
            <mat-label>Contamination</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Contamination">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--EffectiveDate -->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
