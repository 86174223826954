//Import Modules
import { Component, ChangeDetectorRef, OnInit, ViewChild, AfterViewInit, AfterViewChecked } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from "angular-notifier";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//Import Components
import { AddNewYearDialogComponent } from './dialog/add-new-year-dialog/add-new-year-dialog.component';

//Import Services
import { GenerateNewYearService } from "../../shared/services/generate-new-year/generate-new-year.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

export interface MunicipalitiesColumns {
  Select: boolean;
  Municipality: string;
  Years: string[];
}

@Component({
  selector: 'app-generate-year',
  templateUrl: './generate-year.component.html',
  styleUrls: ['./generate-year.component.scss']
})
export class GenerateYearComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  constructor(private fb: FormBuilder, private generateNewYearService: GenerateNewYearService, private notifier: NotifierService, public dialog: MatDialog) { };

  //Generate data
  public generateData: string[] = ['Municipality', 'Years', 'Action'];
  public generateReportForm: FormGroup;

  public dataSource = new MatTableDataSource<MunicipalitiesColumns>();

  ngOnInit() {
    this.getGenerateMunicipalitiesWithYears();
  };

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase(); //toLocaleLowerCase
  };

  //Get table data
  getGenerateMunicipalitiesWithYears() {
    this.generateNewYearService.getMunicipalitiesWithYears().subscribe((response: any) => {
      this.dataSource.data = response as MunicipalitiesColumns[];
    });
  };

  //Open modal
  public openGenerateNewYearDialog(data) {
    const dialogRef = this.dialog.open(AddNewYearDialogComponent, {
      data: { 'id': data }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.generateNewYearService.addGenerateNewYear(result).subscribe((response: any) => {
          if (response.error == 0) {
            this.getGenerateMunicipalitiesWithYears();
            this.notifier.notify("success", response.message);
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };
}
