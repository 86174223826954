//Angular Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { CookieService } from 'ngx-cookie-service';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";

//Angular Material Components
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

//Components
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { GeneralInfoComponent } from './dashboard/general-info/general-info.component';
import { CrimeApplicationComponent } from './dashboard/exposures/crime/crime-application/crime-application.component';
import { GeneralComponent } from './dashboard/exposures/general/general.component';
import { AdditionalComponent } from './dashboard/exposures/additional/additional.component';
import { SpecificComponent } from './dashboard/exposures/specific/specific.component';
import { VehiclesComponent } from './dashboard/exposures/vehicles/vehicles.component';
import { PropertyComponent } from './dashboard/exposures/property/property.component';
import { CyberLiabilityComponent } from './dashboard/exposures/cyber-liability/cyber-liability.component';
import { NonOwnedAircraftLiabilityComponent } from './dashboard/exposures/non-owned-aircraft-liability/non-owned-aircraft-liability.component';
import { SiteEnvironmentalLiabilityComponent } from './dashboard/exposures/site-environmental-liability/site-environmental-liability.component';
import { UstLiabilityComponent } from './dashboard/exposures/ust-liability/ust-liability.component';
import { CrimePositionBondApplicationComponent } from './dashboard/exposures/crime/crime-position-bond-application/crime-position-bond-application.component';
import { StorageTankInventoryLocationComponent } from './dashboard/exposures/storage-tank-inventory-location/storage-tank-inventory-location.component';
import { StorageTankMarinaQuestionnaireComponent } from './dashboard/exposures/storage-tank-marina-questionnaire/storage-tank-marina-questionnaire.component';
import { NewPilotDialogComponent } from './dashboard/exposures/non-owned-aircraft-liability/dialog/new-pilot-dialog/new-pilot-dialog.component';
import { NewLocationDialogComponent } from './dashboard/exposures/ust-liability/dialog/new-location-dialog/new-location-dialog.component';
import { NewEmployeeLocationDialogComponent } from './dashboard/schedules/employee-concentration/dialog/new-employee-location-dialog/new-employee-location-dialog.component';
import { EmployeeConcentrationComponent } from './dashboard/schedules/employee-concentration/employee-concentration.component';
import { LocationsPropertyValuesComponent } from './dashboard/schedules/locations-property-values/locations-property-values.component';
import { NewLocationsPropertyValuesDialogComponent } from './dashboard/schedules/locations-property-values/dialog/new-locations-property-values-dialog/new-locations-property-values-dialog.component';
import { PayrollComponent } from './dashboard/schedules/payroll/payroll.component';
import { NewPayrollDialogComponent } from './dashboard/schedules/payroll/dialog/new-payroll-dialog/new-payroll-dialog.component';
import { VehicleComponent } from './dashboard/schedules/vehicle/vehicle.component';
import { NewVehicleDialogComponent } from './dashboard/schedules/vehicle/dialog/new-vehicle-dialog/new-vehicle-dialog.component';
import { VinComponent } from './dashboard/schedules/vin/vin.component';
import { NewVinDialogComponent } from './dashboard/schedules/vin/dialog/new-vin-dialog/new-vin-dialog.component';
import { EquipmentComponent } from './dashboard/schedules/equipment/equipment.component';
import { NewEquipmentDialogComponent } from './dashboard/schedules/equipment/dialog/new-equipment-dialog/new-equipment-dialog.component';
import { FineArtsComponent } from './dashboard/schedules/fine-arts/fine-arts.component';
import { NewFineArtsDialogComponent } from './dashboard/schedules/fine-arts/dialog/new-fine-arts-dialog/new-fine-arts-dialog.component';
import { WatercraftComponent } from './dashboard/schedules/watercraft/watercraft.component';
import { NewWatercraftDialogComponent } from './dashboard/schedules/watercraft/dialog/new-watercraft-dialog/new-watercraft-dialog.component';
import { UserProfileComponent } from './dashboard/user-profile/user-profile.component';
import { UsersComponent } from './dashboard/users/users.component';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { LoginComponent } from './account/login/login.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { UpdateEquipmentDialogComponent } from './dashboard/schedules/equipment/dialog/update-equipment-dialog/update-equipment-dialog.component';
import { DeleteEquipmentDialogComponent } from './dashboard/schedules/equipment/dialog/delete-equipment-dialog/delete-equipment-dialog.component';
import { DeleteVehicleDialogComponent } from './dashboard/schedules/vehicle/dialog/delete-vehicle-dialog/delete-vehicle-dialog.component';
import { UpdateVehicleDialogComponent } from './dashboard/schedules/vehicle/dialog/update-vehicle-dialog/update-vehicle-dialog.component';
import { UpdatePayrollDialogComponent } from './dashboard/schedules/payroll/dialog/update-payroll-dialog/update-payroll-dialog.component';
import { DeletePayrollDialogComponent } from './dashboard/schedules/payroll/dialog/delete-payroll-dialog/delete-payroll-dialog.component';
import { DeleteWatercraftDialogComponent } from './dashboard/schedules/watercraft/dialog/delete-watercraft-dialog/delete-watercraft-dialog.component';
import { UpdateWatercraftDialogComponent } from './dashboard/schedules/watercraft/dialog/update-watercraft-dialog/update-watercraft-dialog.component';
import { DeleteFineArtDialogComponent } from './dashboard/schedules/fine-arts/dialog/delete-fine-art-dialog/delete-fine-art-dialog.component';
import { UpdateFineArtDialogComponent } from './dashboard/schedules/fine-arts/dialog/update-fine-art-dialog/update-fine-art-dialog.component';
import { UpdateLocationsPropertyDialogComponent } from './dashboard/schedules/locations-property-values/dialog/update-locations-property-dialog/update-locations-property-dialog.component';
import { DeleteLocationsPropertyDialogComponent } from './dashboard/schedules/locations-property-values/dialog/delete-locations-property-dialog/delete-locations-property-dialog.component';
import { NewLocationExposureDialogComponent } from './dashboard/exposures/crime/crime-application/dialog/new-location-exposure-dialog/new-location-exposure-dialog.component';
import { UpdateLocationExposureDialogComponent } from './dashboard/exposures/crime/crime-application/dialog/update-location-exposure-dialog/update-location-exposure-dialog.component';
import { DeleteLocationExposureDialogComponent } from './dashboard/exposures/crime/crime-application/dialog/delete-location-exposure-dialog/delete-location-exposure-dialog.component';
import { NewLossExperienceDialogComponent } from './dashboard/exposures/crime/crime-application/dialog/new-loss-experience-dialog/new-loss-experience-dialog.component';
import { UpdateLossExperienceDialogComponent } from './dashboard/exposures/crime/crime-application/dialog/update-loss-experience-dialog/update-loss-experience-dialog.component';
import { DeleteLossExperienceDialogComponent } from './dashboard/exposures/crime/crime-application/dialog/delete-loss-experience-dialog/delete-loss-experience-dialog.component';
import { CrimePositionBondWorksheetComponent } from './dashboard/exposures/crime/crime-position-bond-worksheet/crime-position-bond-worksheet.component';
import { NewCrimePositonBondWorksheetDialogComponent } from './dashboard/exposures/crime/crime-position-bond-worksheet/dialog/new-crime-positon-bond-worksheet-dialog/new-crime-positon-bond-worksheet-dialog.component';
import { UpdateCrimePositonBondWorksheetDialogComponent } from './dashboard/exposures/crime/crime-position-bond-worksheet/dialog/update-crime-positon-bond-worksheet-dialog/update-crime-positon-bond-worksheet-dialog.component';
import { DeleteCrimePositonBondWorksheetDialogComponent } from './dashboard/exposures/crime/crime-position-bond-worksheet/dialog/delete-crime-positon-bond-worksheet-dialog/delete-crime-positon-bond-worksheet-dialog.component';
import { UpdatePilotDialogComponent } from './dashboard/exposures/non-owned-aircraft-liability/dialog/update-pilot-dialog/update-pilot-dialog.component';
import { DeletePilotDialogComponent } from './dashboard/exposures/non-owned-aircraft-liability/dialog/delete-pilot-dialog/delete-pilot-dialog.component';
import { UpdateLocationDialogComponent } from './dashboard/exposures/ust-liability/dialog/update-location-dialog/update-location-dialog.component';
import { DeleteLocationDialogComponent } from './dashboard/exposures/ust-liability/dialog/delete-location-dialog/delete-location-dialog.component';
import { UpdateEmployeeLocationDialogComponent } from './dashboard/schedules/employee-concentration/dialog/update-employee-location-dialog/update-employee-location-dialog.component';
import { DeleteEmployeeLocationDialogComponent } from './dashboard/schedules/employee-concentration/dialog/delete-employee-location-dialog/delete-employee-location-dialog.component';
import { NewStorageTankLocationComponent } from './dashboard/exposures/storage-tank-inventory-location/dialog/new-storage-tank-location/new-storage-tank-location.component';
import { UpdateStorageTankLocationComponent } from './dashboard/exposures/storage-tank-inventory-location/dialog/update-storage-tank-location/update-storage-tank-location.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ConfirmAccountComponent } from './account/confirm-account/confirm-account.component';
import { CreateAccountComponent } from './account/create-account/create-account.component';
import { DeleteAccountComponent } from './account/delete-account/delete-account.component';
import { EditAccountComponent } from './account/edit-account/edit-account.component';

//Services
import { MunicipalitiesService } from './shared/services/municipalities/municipalities.service';
import { AccountService } from './account/service/account.service';
import { JwtInterceptor } from './shared/services/auth/helpers/jwt.interceptor';

//Pipes
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { CreatePasswordComponent } from './account/create-password/create-password.component';
import { ExposuresComponent } from './dashboard/exposures/exposures.component';
import { SchedulesComponent } from './dashboard/schedules/schedules.component';
import { FreezeUpdatesComponent } from './dashboard/freeze-updates/freeze-updates.component';
import { GenerateYearComponent } from './dashboard/generate-year/generate-year.component';
import { AddNewYearDialogComponent } from './dashboard/generate-year/dialog/add-new-year-dialog/add-new-year-dialog.component';
import { RiskManagersComponent } from './dashboard/risk-managers/risk-managers.component';
import { AddNewRiskManagerDialogComponent } from './dashboard/risk-managers/dialog/add-new-risk-manager-dialog/add-new-risk-manager-dialog.component';
import { EditRiskManagerDialogComponent } from './dashboard/risk-managers/dialog/edit-risk-manager-dialog/edit-risk-manager-dialog.component';
import { DeleteRiskManagerDialogComponent } from './dashboard/risk-managers/dialog/delete-risk-manager-dialog/delete-risk-manager-dialog.component';
import { UploadsComponent } from './dashboard/uploads/uploads.component';
import { DepartmentsHelperComponent } from './dashboard/uploads/dialog/departments-helper/departments-helper.component';
import { DepartmentsComponent } from './dashboard/departments/departments.component';
import { CreateDepartmentComponent } from './dashboard/departments/create-department/create-department.component';
import { EditDepartmentComponent } from './dashboard/departments/edit-department/edit-department.component';
import { DeleteDepartmentComponent } from './dashboard/departments/delete-department/delete-department.component';
import { MunipalitiesComponent } from './dashboard/munipalities/munipalities.component';
import { AddNewMunipalityComponent } from './dashboard/munipalities/dialog/add-new-munipality/add-new-munipality.component';
import { EditMunipalityComponent } from './dashboard/munipalities/dialog/edit-munipality/edit-munipality.component';
import { TwoStepAuthComponent } from './account/two-step-auth/two-step-auth.component';
import { YesNoPipe } from './yes-no.pipe';
/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 2
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidebarComponent,
    GeneralInfoComponent,
    CrimeApplicationComponent,
    GeneralComponent,
    AdditionalComponent,
    SpecificComponent,
    VehiclesComponent,
    PropertyComponent,
    CyberLiabilityComponent,
    NonOwnedAircraftLiabilityComponent,
    SiteEnvironmentalLiabilityComponent,
    UstLiabilityComponent,
    CrimePositionBondApplicationComponent,
    StorageTankInventoryLocationComponent,
    StorageTankMarinaQuestionnaireComponent,
    NewPilotDialogComponent,
    NewLocationDialogComponent,
    EmployeeConcentrationComponent,
    NewEmployeeLocationDialogComponent,
    LocationsPropertyValuesComponent,
    NewLocationsPropertyValuesDialogComponent,
    PayrollComponent,
    NewPayrollDialogComponent,
    VehicleComponent,
    NewVehicleDialogComponent,
    VinComponent,
    NewVinDialogComponent,
    EquipmentComponent,
    NewEquipmentDialogComponent,
    FineArtsComponent,
    NewFineArtsDialogComponent,
    WatercraftComponent,
    NewWatercraftDialogComponent,
    UserProfileComponent,
    UsersComponent,
    ReportsComponent,
    LoginComponent,
    ForgotPasswordComponent,
    UpdateEquipmentDialogComponent,
    DeleteEquipmentDialogComponent,
    DeleteVehicleDialogComponent,
    UpdateVehicleDialogComponent,
    UpdatePayrollDialogComponent,
    DeletePayrollDialogComponent,
    DeleteWatercraftDialogComponent,
    UpdateWatercraftDialogComponent,
    DeleteFineArtDialogComponent,
    UpdateFineArtDialogComponent,
    UpdateLocationsPropertyDialogComponent,
    DeleteLocationsPropertyDialogComponent,
    NewLocationExposureDialogComponent,
    UpdateLocationExposureDialogComponent,
    DeleteLocationExposureDialogComponent,
    NewLossExperienceDialogComponent,
    UpdateLossExperienceDialogComponent,
    DeleteLossExperienceDialogComponent,
    CrimePositionBondWorksheetComponent,
    NewCrimePositonBondWorksheetDialogComponent,
    UpdateCrimePositonBondWorksheetDialogComponent,
    DeleteCrimePositonBondWorksheetDialogComponent,
    UpdatePilotDialogComponent,
    DeletePilotDialogComponent,
    UpdateLocationDialogComponent,
    DeleteLocationDialogComponent,
    UpdateEmployeeLocationDialogComponent,
    DeleteEmployeeLocationDialogComponent,
    NewStorageTankLocationComponent,
    UpdateStorageTankLocationComponent,
    ResetPasswordComponent,
    ConfirmAccountComponent,
    CreateAccountComponent,
    DeleteAccountComponent,
    EditAccountComponent,
    ChangePasswordComponent,
    CreatePasswordComponent,
    ExposuresComponent,
    SchedulesComponent,
    FreezeUpdatesComponent,
    GenerateYearComponent,
    AddNewYearDialogComponent,
    RiskManagersComponent,
    AddNewRiskManagerDialogComponent,
    EditRiskManagerDialogComponent,
    DeleteRiskManagerDialogComponent,
    UploadsComponent,
    DepartmentsHelperComponent,
    DepartmentsComponent,
    CreateDepartmentComponent,
    EditDepartmentComponent,
    DeleteDepartmentComponent,
    MunipalitiesComponent,
    AddNewMunipalityComponent,
    EditMunipalityComponent,
    TwoStepAuthComponent,
    YesNoPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatTooltipModule,
    MatMenuModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatExpansionModule,
    NgMaterialMultilevelMenuModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    NotifierModule.withConfig(customNotifierOptions),
    NgxExtendedPdfViewerModule,
    NgHttpLoaderModule.forRoot(),
    NgxMaskModule.forRoot(),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    CurrencyMaskModule
  ],
  providers: [MatDatepickerModule, MunicipalitiesService, CookieService, AccountService, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
