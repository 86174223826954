//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
import { MatTableDataSource } from '@angular/material/table';

import { ExposuresService } from '../../../../shared/services/exposures/exposures.service';
import { RenewalApplicationsService } from '../../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../../shared/services/auth/auth.service';

import { NewCrimePositonBondWorksheetDialogComponent } from './dialog/new-crime-positon-bond-worksheet-dialog/new-crime-positon-bond-worksheet-dialog.component';
import { UpdateCrimePositonBondWorksheetDialogComponent } from './dialog/update-crime-positon-bond-worksheet-dialog/update-crime-positon-bond-worksheet-dialog.component';
import { DeleteCrimePositonBondWorksheetDialogComponent } from './dialog/delete-crime-positon-bond-worksheet-dialog/delete-crime-positon-bond-worksheet-dialog.component';

@Component({
  selector: 'app-crime-position-bond-worksheet',
  templateUrl: './crime-position-bond-worksheet.component.html',
  styleUrls: ['./crime-position-bond-worksheet.component.scss']
})
export class CrimePositionBondWorksheetComponent implements OnInit {
  constructor(public dialog: MatDialog, private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, private notifier: NotifierService) { }

  public activeMunicipality;
  public displayedColumnsPositionBondWorksheet: string[] = ["position", "name", "limitRequired", "bondCopy", "applicationCopy", "applicationDate", "currentPolicyDuration", "asOf", "action"];
  public positionBondWorksheetDataSource;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.activeMunicipality = response;
        this.getTableList(response);
      }
    });
  };

  public getTableList(data) {
    this.exposuresService.getList(data.municipalityID, data.municipalityYear, 'GetListPositionBondWorksheet').subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.positionBondWorksheetDataSource = new MatTableDataSource(response);
      }
    });
  };

  public openAddLocationExposureDialog() {
    const dialogRef = this.dialog.open(NewCrimePositonBondWorksheetDialogComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear,
        'UserId': this.authService.activeAccount.userID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.add(result, 'AddPositionBondWorksheet').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully created new record");
          } else {
            this.notifier.notify('error', response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public updateCrimePositonBond(data) {
    const dialogRef = this.dialog.open(UpdateCrimePositonBondWorksheetDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.update(result, 'UpdatePositionBondWorksheet').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully updated existing record");

          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public deleteCrimePositonBond(data) {
    const dialogRef = this.dialog.open(DeleteCrimePositonBondWorksheetDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.delete(result, 'DeletePositionBondWorksheet').subscribe((response: any) => {
          if (response.error == 0) {
            this.getTableList(this.activeMunicipality);
            this.notifier.notify("success", "Successfully deleted existing record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

};
