//Import Modules
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from "angular-notifier";
import { FormValidator } from '../../../../shared/pipes/FormValidator';

//Import Services
import { RenewalApplicationsService } from '../../../../shared/services/renewal-applications/renewal-applications.service';
import { ExposuresService } from '../../../../shared/services/exposures/exposures.service';
import { AuthService } from '../../../../shared/services/auth/auth.service';

@Component({
  selector: 'app-crime-position-bond-application',
  templateUrl: './crime-position-bond-application.component.html',
  styleUrls: ['./crime-position-bond-application.component.scss']
})
export class CrimePositionBondApplicationComponent implements OnInit {
  constructor(private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService, public dialog: MatDialog, private notifier: NotifierService, private render: Renderer2) {
    this.bondApplicationForm = new FormGroup({
      PositionBondApplicationID: new FormControl(0),
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]),
      ApplicantFirstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ApplicantMiddleName: new FormControl('', Validators.maxLength(100)),
      ApplicantLastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ApplicantStreetName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ApplicantCity: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      ApplicantState: new FormControl('', Validators.compose([Validators.minLength(2), Validators.maxLength(2), Validators.required, FormValidator.patternValidator(/^[A-Z]*$/, { isCapital: true })])), //STRING
      ApplicantCountry: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      ApplicantZipCode: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(5), Validators.required, FormValidator.patternValidator(/^[0-9]*$/, { isNumbersOnly: true })])), //STRING
      ApplicantPhone: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      ApplicantFax: new FormControl('', Validators.maxLength(20)),
      ApplicantEmail: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.email]), //STRING
      ApplicantSSN: new FormControl('', Validators.compose([Validators.maxLength(15), Validators.required])), //STRING
      TypeOfBond: new FormControl('', Validators.maxLength(50)),
      BondAmount: new FormControl('', Validators.maxLength(20)),
      BondEffectiveDate: new FormControl('', Validators.required), //DATE
      IsSpecialBondIssue: new FormControl(false),
      WhatPurpose: new FormControl('', Validators.maxLength(100)),
      IsBondDeclinedByAnotherCompany: new FormControl(false),
      BondDeclinedByAnotherCompanySurety: new FormControl({ value: '' }, Validators.maxLength(100)),
      BondDeclinedByAnotherCompanyReason: new FormControl({ value: '' }, Validators.maxLength(100)),
      IsCurrentlyBonded: new FormControl(false),
      IsCurrentlyBondedSurety: new FormControl('', Validators.maxLength(100)),
      IsCurrentlyBondedReason: new FormControl('', Validators.maxLength(100)),
      IsLawSuitOrJudgments: new FormControl(false),
      IsBankruptcy: new FormControl(false),
      IsConvictedCrime: new FormControl(false),
      IsLicenseSuspended: new FormControl(false),
      IsPartyToABondClaim: new FormControl(false),
      ObligeeFirstName: new FormControl('', Validators.maxLength(100)),
      ObligeeMiddleName: new FormControl('', Validators.maxLength(100)),
      ObligeeLastName: new FormControl('', Validators.maxLength(100)),
      ObligeeStreetName: new FormControl('', Validators.maxLength(100)),
      ObligeeCity: new FormControl('', Validators.maxLength(100)),
      ObligeeState: new FormControl('', Validators.compose([Validators.minLength(2), Validators.maxLength(2), FormValidator.patternValidator(/^[A-Z]*$/, { isCapital: true })])), //STRING
      ObligeeCountry: new FormControl('', Validators.maxLength(100)),
      ObligeeZipCode: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(5), FormValidator.patternValidator(/^[0-9]*$/, { isNumbersOnly: true })])), //STRING
      IsExperiencedPublicOfficialLosses: new FormControl(false),
      ExperiencedPublicOfficialLossesDetails: new FormControl({ value: '' }, Validators.maxLength(100)),
      TitleOfApplicant: new FormControl('', Validators.maxLength(50)),
      Elected: new FormControl(false),
      Appointed: new FormControl(false),
      TermsInYear: new FormControl(0, Validators.required),
      StartDate: new FormControl('', Validators.required), //DATE
      EndDate: new FormControl('', Validators.required),  //DATE
      IsApplicantOccupiedPositionPreviously: new FormControl(false),
      PositionDuration: new FormControl('', Validators.maxLength(100)),
      IsApplicantCurrentlyHaveLiability: new FormControl(false),
      LiabilityCompanyName: new FormControl('', Validators.maxLength(50)),
      LiabilityLimit: new FormControl('', Validators.maxLength(20)),
      LiabilityPolicyNumber: new FormControl('', Validators.maxLength(20)),
      DescribeAgentApplicantRelationship: new FormControl('', Validators.maxLength(100)),
      IsAgentRecommendApplicant: new FormControl(false),
      AgentRecommendReson: new FormControl('', Validators.maxLength(100)),
      AmountHandledAnnually: new FormControl('', Validators.maxLength(20)),
      LargetsAmountUnderYourControl: new FormControl('', Validators.maxLength(20)),
      IsFundDeposited: new FormControl(false),
      IsAggreedToUseDepositories: new FormControl(false),
      IsApplicantHaveWithdrawAuthority: new FormControl(false),
      IsCounterSignatureRequired: new FormControl(false),
      CounterSignatureByWhom: new FormControl('', Validators.maxLength(100)),
      IsApplicantCustodianOfSecurities: new FormControl(false),
      CustodianAmount: new FormControl('', Validators.maxLength(20)),
      SecurityKept: new FormControl('', Validators.maxLength(100)),
      IsJointControl: new FormControl(false),
      JointControlByWhom: new FormControl('', Validators.maxLength(100)),
      IsAccountAuditedAnnually: new FormControl(false),
      AccountAuditedByWhom: new FormControl('', Validators.maxLength(100)),
      IsCPARecommendations: new FormControl(false),
      IsApplicantCollectTaxes: new FormControl(false),
      ApplicantMakeReportofInSolvenciesDelinquencies: new FormControl('', Validators.maxLength(100)),
      IsApplicantResponsibleForFundInvestment: new FormControl(false),
      IsObligeeApprovedInvestmentPolicy: new FormControl(false),
      IsOtherThanApplicantCheckingPolicy: new FormControl(false),
      EffectiveDate: new FormControl('', Validators.required), //DATE
      UserId: new FormControl('', [Validators.required, Validators.maxLength(128)])
    });

    //IsSpecialBondIssue
    this.bondApplicationForm.controls['IsSpecialBondIssue'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('WhatPurpose').enable() : this.bondApplicationForm.get('WhatPurpose').disable();
    });

    //IsBondDeclinedByAnotherCompany
    this.bondApplicationForm.controls['IsBondDeclinedByAnotherCompany'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('BondDeclinedByAnotherCompanySurety').enable() : this.bondApplicationForm.get('BondDeclinedByAnotherCompanySurety').disable();
      value ? this.bondApplicationForm.get('BondDeclinedByAnotherCompanyReason').enable() : this.bondApplicationForm.get('BondDeclinedByAnotherCompanyReason').disable();
    });

    //IsCurrentlyBonded
    this.bondApplicationForm.controls['IsCurrentlyBonded'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('IsCurrentlyBondedSurety').enable() : this.bondApplicationForm.get('IsCurrentlyBondedSurety').disable();
      value ? this.bondApplicationForm.get('IsCurrentlyBondedReason').enable() : this.bondApplicationForm.get('IsCurrentlyBondedReason').disable();
    });

    //IsExperiencedPublicOfficialLosses
    this.bondApplicationForm.controls['IsExperiencedPublicOfficialLosses'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('ExperiencedPublicOfficialLossesDetails').enable() : this.bondApplicationForm.get('ExperiencedPublicOfficialLossesDetails').disable();
    });

    //IsApplicantOccupiedPositionPreviously
    this.bondApplicationForm.controls['IsApplicantOccupiedPositionPreviously'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('PositionDuration').enable() : this.bondApplicationForm.get('PositionDuration').disable();
    });

    //IsApplicantCurrentlyHaveLiability
    this.bondApplicationForm.controls['IsApplicantCurrentlyHaveLiability'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('LiabilityCompanyName').enable() : this.bondApplicationForm.get('LiabilityCompanyName').disable();
      value ? this.bondApplicationForm.get('LiabilityLimit').enable() : this.bondApplicationForm.get('LiabilityLimit').disable();
      value ? this.bondApplicationForm.get('LiabilityPolicyNumber').enable() : this.bondApplicationForm.get('LiabilityPolicyNumber').disable();
    });

    //IsAgentRecommendApplicant
    this.bondApplicationForm.controls['IsAgentRecommendApplicant'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('AgentRecommendReson').enable() : this.bondApplicationForm.get('AgentRecommendReson').disable();
    });

    //IsCounterSignatureRequired
    this.bondApplicationForm.controls['IsCounterSignatureRequired'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('CounterSignatureByWhom').enable() : this.bondApplicationForm.get('CounterSignatureByWhom').disable();
    });

    //IsApplicantCustodianOfSecurities
    this.bondApplicationForm.controls['IsApplicantCustodianOfSecurities'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('CustodianAmount').enable() : this.bondApplicationForm.get('CustodianAmount').disable();
      value ? this.bondApplicationForm.get('SecurityKept').enable() : this.bondApplicationForm.get('SecurityKept').disable();
    });

    //IsJointControl
    this.bondApplicationForm.controls['IsJointControl'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('JointControlByWhom').enable() : this.bondApplicationForm.get('JointControlByWhom').disable();
    });

    //IsAccountAuditedAnnually
    this.bondApplicationForm.controls['IsAccountAuditedAnnually'].valueChanges.subscribe(value => {
      value ? this.bondApplicationForm.get('AccountAuditedByWhom').enable() : this.bondApplicationForm.get('AccountAuditedByWhom').disable();
    });
  };

  @ViewChild("bondApplicationFormHandle", { static: true }) bondApplicationFormHandle: ElementRef;

  public activeMunicipality;
  public bondApplicationForm: FormGroup;
  public availableApplications;
  public hideApplicantSSN = true;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.bondApplicationForm.disable();
        this.render.setStyle(this.bondApplicationFormHandle.nativeElement, 'display', 'none');
        return;
      } else {
        this.activeMunicipality = response;
        this.bondApplicationForm.reset();
        this.bondApplicationForm.disable();
        this.render.setStyle(this.bondApplicationFormHandle.nativeElement, 'display', 'none');
        this.getpositionBondApplication(response);
      }
    });
  };

  public getpositionBondApplication(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetListPositionBondApplication').subscribe((response: any) => {
      this.availableApplications = response;
    });
  };

  public changeActiveApplication(activeApplication) {
    if (activeApplication == 0) {
      this.bondApplicationForm.enable();
      this.bondApplicationForm.patchValue({
        'PositionBondApplicationID': 0,
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear,
        'ApplicantFirstName': '',
        'ApplicantMiddleName': '',
        'ApplicantLastName': '',
        'ApplicantStreetName': '',
        'ApplicantCity': '',
        'ApplicantState': '',
        'ApplicantCountry': '',
        'ApplicantZipCode': '',
        'ApplicantPhone': '',
        'ApplicantFax': '',
        'ApplicantEmail': '',
        'ApplicantSSN': '',
        'TypeOfBond': '',
        'BondAmount': '',
        'BondEffectiveDate': '',
        'IsSpecialBondIssue': false,
        'WhatPurpose': '',
        'IsBondDeclinedByAnotherCompany': false,
        'BondDeclinedByAnotherCompanySurety': '',
        'BondDeclinedByAnotherCompanyReason': '',
        'IsCurrentlyBonded': false,
        'IsCurrentlyBondedSurety': '',
        'IsCurrentlyBondedReason': '',
        'IsLawSuitOrJudgments': false,
        'IsBankruptcy': false,
        'IsConvictedCrime': false,
        'IsLicenseSuspended': false,
        'IsPartyToABondClaim': false,
        'ObligeeFirstName': '',
        'ObligeeMiddleName': '',
        'ObligeeLastName': '',
        'ObligeeStreetName': '',
        'ObligeeCity': '',
        'ObligeeState': '',
        'ObligeeCountry': '',
        'ObligeeZipCode': '',
        'IsExperiencedPublicOfficialLosses': false,
        'ExperiencedPublicOfficialLossesDetails': '',
        'TitleOfApplicant': '',
        'Elected': false,
        'Appointed': false,
        'TermsInYear': 0,
        'StartDate': '',
        'EndDate': '',
        'IsApplicantOccupiedPositionPreviously': false,
        'PositionDuration': '',
        'IsApplicantCurrentlyHaveLiability': false,
        'LiabilityCompanyName': '',
        'LiabilityLimit': '',
        'LiabilityPolicyNumber': '',
        'DescribeAgentApplicantRelationship': '',
        'IsAgentRecommendApplicant': false,
        'AgentRecommendReson': '',
        'AmountHandledAnnually': '',
        'LargetsAmountUnderYourControl': '',
        'IsFundDeposited': false,
        'IsAggreedToUseDepositories': false,
        'IsApplicantHaveWithdrawAuthority': false,
        'IsCounterSignatureRequired': false,
        'CounterSignatureByWhom': '',
        'IsApplicantCustodianOfSecurities': false,
        'CustodianAmount': '',
        'SecurityKept': '',
        'IsJointControl': false,
        'JointControlByWhom': '',
        'IsAccountAuditedAnnually': false,
        'AccountAuditedByWhom': '',
        'IsCPARecommendations': false,
        'IsApplicantCollectTaxes': false,
        'ApplicantMakeReportofInSolvenciesDelinquencies': '',
        'IsApplicantResponsibleForFundInvestment': false,
        'IsObligeeApprovedInvestmentPolicy': false,
        'IsOtherThanApplicantCheckingPolicy': false,
        'EffectiveDate': '',
        'UserId': this.authService.activeAccount.userID
      })
      this.render.setStyle(this.bondApplicationFormHandle.nativeElement, 'display', 'block');
    } else {
      this.bondApplicationForm.patchValue({
        'PositionBondApplicationID': activeApplication.positionBondApplicationID,
        'MunicipalityID': activeApplication.municipalityID,
        'MunicipalityYear': activeApplication.municipalityYear,
        'ApplicantFirstName': activeApplication.applicantFirstName,
        'ApplicantMiddleName': activeApplication.applicantMiddleName,
        'ApplicantLastName': activeApplication.applicantLastName,
        'ApplicantStreetName': activeApplication.applicantStreetName,
        'ApplicantCity': activeApplication.applicantCity,
        'ApplicantState': activeApplication.applicantState,
        'ApplicantCountry': activeApplication.applicantCountry,
        'ApplicantZipCode': activeApplication.applicantZipCode,
        'ApplicantPhone': activeApplication.applicantPhone,
        'ApplicantFax': activeApplication.applicantFax,
        'ApplicantEmail': activeApplication.applicantEmail,
        'ApplicantSSN': activeApplication.applicantSSN,
        'TypeOfBond': activeApplication.typeOfBond,
        'BondAmount': activeApplication.bondAmount,
        'BondEffectiveDate': activeApplication.bondEffectiveDate,
        'IsSpecialBondIssue': activeApplication.isSpecialBondIssue,
        'WhatPurpose': activeApplication.whatPurpose,
        'IsBondDeclinedByAnotherCompany': activeApplication.isBondDeclinedByAnotherCompany,
        'BondDeclinedByAnotherCompanySurety': activeApplication.bondDeclinedByAnotherCompanySurety,
        'BondDeclinedByAnotherCompanyReason': activeApplication.bondDeclinedByAnotherCompanyReason,
        'IsCurrentlyBonded': activeApplication.isCurrentlyBonded,
        'IsCurrentlyBondedSurety': activeApplication.isCurrentlyBondedSurety,
        'IsCurrentlyBondedReason': activeApplication.isCurrentlyBondedReason,
        'IsLawSuitOrJudgments': activeApplication.isLawSuitOrJudgments,
        'IsBankruptcy': activeApplication.isBankruptcy,
        'IsConvictedCrime': activeApplication.isConvictedCrime,
        'IsLicenseSuspended': activeApplication.isLicenseSuspended,
        'IsPartyToABondClaim': activeApplication.isPartyToABondClaim,
        'ObligeeFirstName': activeApplication.obligeeFirstName,
        'ObligeeMiddleName': activeApplication.obligeeMiddleName,
        'ObligeeLastName': activeApplication.obligeeLastName,
        'ObligeeStreetName': activeApplication.obligeeStreetName,
        'ObligeeCity': activeApplication.obligeeCity,
        'ObligeeState': activeApplication.obligeeState,
        'ObligeeCountry': activeApplication.obligeeCountry,
        'ObligeeZipCode': activeApplication.obligeeZipCode,
        'IsExperiencedPublicOfficialLosses': activeApplication.isExperiencedPublicOfficialLosses,
        'ExperiencedPublicOfficialLossesDetails': activeApplication.experiencedPublicOfficialLossesDetails,
        'TitleOfApplicant': activeApplication.titleOfApplicant,
        'Elected': activeApplication.elected,
        'Appointed': activeApplication.appointed,
        'TermsInYear': activeApplication.termsInYear,
        'StartDate': activeApplication.startDate,
        'EndDate': activeApplication.endDate,
        'IsApplicantOccupiedPositionPreviously': activeApplication.isApplicantOccupiedPositionPreviously,
        'PositionDuration': activeApplication.positionDuration,
        'IsApplicantCurrentlyHaveLiability': activeApplication.isApplicantCurrentlyHaveLiability,
        'LiabilityCompanyName': activeApplication.liabilityCompanyName,
        'LiabilityLimit': activeApplication.liabilityLimit,
        'LiabilityPolicyNumber': activeApplication.liabilityPolicyNumber,
        'DescribeAgentApplicantRelationship': activeApplication.describeAgentApplicantRelationship,
        'IsAgentRecommendApplicant': activeApplication.isAgentRecommendApplicant,
        'AgentRecommendReson': activeApplication.agentRecommendReson,
        'AmountHandledAnnually': activeApplication.amountHandledAnnually,
        'LargetsAmountUnderYourControl': activeApplication.largetsAmountUnderYourControl,
        'IsFundDeposited': activeApplication.isFundDeposited,
        'IsAggreedToUseDepositories': activeApplication.isAggreedToUseDepositories,
        'IsApplicantHaveWithdrawAuthority': activeApplication.isApplicantHaveWithdrawAuthority,
        'IsCounterSignatureRequired': activeApplication.isCounterSignatureRequired,
        'CounterSignatureByWhom': activeApplication.counterSignatureByWhom,
        'IsApplicantCustodianOfSecurities': activeApplication.isApplicantCustodianOfSecurities,
        'CustodianAmount': activeApplication.custodianAmount,
        'SecurityKept': activeApplication.securityKept,
        'IsJointControl': activeApplication.isJointControl,
        'JointControlByWhom': activeApplication.jointControlByWhom,
        'IsAccountAuditedAnnually': activeApplication.isAccountAuditedAnnually,
        'AccountAuditedByWhom': activeApplication.accountAuditedByWhom,
        'IsCPARecommendations': activeApplication.isCPARecommendations,
        'IsApplicantCollectTaxes': activeApplication.isApplicantCollectTaxes,
        'ApplicantMakeReportofInSolvenciesDelinquencies': activeApplication.applicantMakeReportofInSolvenciesDelinquencies,
        'IsApplicantResponsibleForFundInvestment': activeApplication.isApplicantResponsibleForFundInvestment,
        'IsObligeeApprovedInvestmentPolicy': activeApplication.isObligeeApprovedInvestmentPolicy,
        'IsOtherThanApplicantCheckingPolicy': activeApplication.isOtherThanApplicantCheckingPolicy,
        'EffectiveDate': activeApplication.effectiveDate,
        'UserId': activeApplication.userId
      });
      this.render.setStyle(this.bondApplicationFormHandle.nativeElement, 'display', 'block');
      this.bondApplicationForm.enable();
    };
  };

  public submitForm(form) {
    if (form.valid && form.value.PositionBondApplicationID == 0) {
      this.exposuresService.add(form.getRawValue(), 'AddPositionBondApplication').subscribe((response: any) => {
        if (response.error == 0) {
          this.getpositionBondApplication(this.activeMunicipality);
          this.notifier.notify("success", "Successfully created new record");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else if (form.valid) {
      this.exposuresService.update(form.getRawValue(), 'UpdatePositionBondApplication').subscribe((response: any) => {
        if (response.error == 0) {
          this.getpositionBondApplication(this.activeMunicipality);
          this.notifier.notify("success", "Successfully updated existing record");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };
};
