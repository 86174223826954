//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-update-employee-location-dialog',
  templateUrl: './update-employee-location-dialog.component.html',
  styleUrls: ['./update-employee-location-dialog.component.scss']
})
export class UpdateEmployeeLocationDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UpdateEmployeeLocationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.updateEmployeeLocation = new FormGroup({
      EmployeeLocations_yearId: new FormControl(0), //INT
      EmployeeLocationID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //INT
      NumberOfEmployees: new FormControl(null, Validators.required), //INT
      EmployeesPerFloor: new FormControl(null, Validators.required), //INT
      Shift1Employees: new FormControl(null, Validators.required), //INT
      Shift2Employees: new FormControl(null, Validators.required), //INT
      Shift3Employees: new FormControl(null, Validators.required), //INT
      PropLocation: new FormControl('', [Validators.required, Validators.maxLength(150)]), //String
      HoursOfOperation: new FormControl('', Validators.maxLength(50)), //String
      FloorsOccupied: new FormControl('', Validators.maxLength(50)), //String
      BuildingConstruction: new FormControl('', Validators.maxLength(50)), //String
      NumberOfStories: new FormControl(0), //INT
      YearBuilt: new FormControl(0), //INT
      EffectiveDate: new FormControl('', Validators.required), //DATE
      UserId: new FormControl([Validators.required, Validators.maxLength(128)]) //STRING
    });
  };

  public updateEmployeeLocation: FormGroup;

  ngOnInit(): void {
    this.updateEmployeeLocation.patchValue({
      'EmployeeLocations_yearId': this.data.employeeLocations_yearId,
      'EmployeeLocationID': this.data.employeeLocationID,
      'MunicipalityID': this.data.municipalityID,
      'MunicipalityYear': this.data.municipalityYear,
      'NumberOfEmployees': this.data.numberOfEmployees,
      'EmployeesPerFloor': this.data.employeesPerFloor,
      'Shift1Employees': this.data.shift1Employees,
      'Shift2Employees': this.data.shift2Employees,
      'Shift3Employees': this.data.shift3Employees,
      'PropLocation': this.data.propLocation,
      'HoursOfOperation': this.data.hoursOfOperation,
      'FloorsOccupied': this.data.floorsOccupied,
      'BuildingConstruction': this.data.buildingConstruction,
      'NumberOfStories': this.data.numberOfStories,
      'YearBuilt': this.data.yearBuilt,
      'UserId': this.data.userId,
      'EffectiveDate': this.data.effectiveDate
    });
  };

  //Close modal and pass form data to controller
  submitupdateEmployeeLocationForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
