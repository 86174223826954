//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from "angular-notifier";

//Import Services
import { RenewalApplicationsService } from '../../../shared/services/renewal-applications/renewal-applications.service';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { ExposuresService } from '../../../shared/services/exposures/exposures.service';

//Import Components
import { NewStorageTankLocationComponent } from './dialog/new-storage-tank-location/new-storage-tank-location.component';
import { UpdateStorageTankLocationComponent } from './dialog/update-storage-tank-location/update-storage-tank-location.component';

@Component({
  selector: 'app-storage-tank-inventory-location',
  templateUrl: './storage-tank-inventory-location.component.html',
  styleUrls: ['./storage-tank-inventory-location.component.scss']
})
export class StorageTankInventoryLocationComponent implements OnInit {
  constructor(public dialog: MatDialog, private notifier: NotifierService, private renewalApplicationsService: RenewalApplicationsService, private exposuresService: ExposuresService, private authService: AuthService) {
    this.storageTankInventoryForm = new FormGroup({
      StorageTankInventoryDetails_yearId: new FormControl(0), //INT
      StorageTankInventoryDetailsID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      FacilityName: new FormControl('', Validators.maxLength(100)), //STRING
      FacilityAddress: new FormControl('', Validators.maxLength(100)), //STRING
      FacilityDate: new FormControl(''), //DATE
      FacilityId: new FormControl('', Validators.maxLength(50)), //STRING
      TankNo_1: new FormControl('', Validators.maxLength(50)), //STRING
      TankNo_2: new FormControl('', Validators.maxLength(50)), //STRING
      TankNo_3: new FormControl('', Validators.maxLength(50)), //STRING
      TankNo_4: new FormControl('', Validators.maxLength(50)), //STRING
      TankNo_5: new FormControl('', Validators.maxLength(50)), //STRING
      TankNo_6: new FormControl('', Validators.maxLength(50)), //STRING
      UST_AST1: new FormControl('', Validators.maxLength(50)), //STRING
      UST_AST2: new FormControl('', Validators.maxLength(50)), //STRING
      UST_AST3: new FormControl('', Validators.maxLength(50)), //STRING
      UST_AST4: new FormControl('', Validators.maxLength(50)), //STRING
      UST_AST5: new FormControl('', Validators.maxLength(50)), //STRING
      UST_AST6: new FormControl('', Validators.maxLength(50)), //STRING
      InstallDateYear1: new FormControl('', Validators.maxLength(50)), //STRING
      InstallDateYear2: new FormControl('', Validators.maxLength(50)), //STRING
      InstallDateYear3: new FormControl('', Validators.maxLength(50)), //STRING
      InstallDateYear4: new FormControl('', Validators.maxLength(50)), //STRING
      InstallDateYear5: new FormControl('', Validators.maxLength(50)), //STRING
      InstallDateYear6: new FormControl('', Validators.maxLength(50)), //STRING
      Capacity1: new FormControl('', Validators.maxLength(50)), //STRING
      Capacity2: new FormControl('', Validators.maxLength(50)), //STRING
      Capacity3: new FormControl('', Validators.maxLength(50)), //STRING
      Capacity4: new FormControl('', Validators.maxLength(50)), //STRING
      Capacity5: new FormControl('', Validators.maxLength(50)), //STRING
      Capacity6: new FormControl('', Validators.maxLength(50)), //STRING
      Contents1: new FormControl('', Validators.maxLength(50)), //STRING
      Contents2: new FormControl('', Validators.maxLength(50)), //STRING
      Contents3: new FormControl('', Validators.maxLength(50)), //STRING
      Contents4: new FormControl('', Validators.maxLength(50)), //STRING
      Contents5: new FormControl('', Validators.maxLength(50)), //STRING
      Contents6: new FormControl('', Validators.maxLength(50)), //STRING
      TankConstructionMaterial1: new FormControl('', Validators.maxLength(50)), //STRING
      TankConstructionMaterial2: new FormControl('', Validators.maxLength(50)), //STRING
      TankConstructionMaterial3: new FormControl('', Validators.maxLength(50)), //STRING
      TankConstructionMaterial4: new FormControl('', Validators.maxLength(50)), //STRING
      TankConstructionMaterial5: new FormControl('', Validators.maxLength(50)), //STRING
      TankConstructionMaterial6: new FormControl('', Validators.maxLength(50)), //STRING
      OverfillSpillProtection1: new FormControl('', Validators.maxLength(50)), //STRING
      OverfillSpillProtection2: new FormControl('', Validators.maxLength(50)), //STRING
      OverfillSpillProtection3: new FormControl('', Validators.maxLength(50)), //STRING
      OverfillSpillProtection4: new FormControl('', Validators.maxLength(50)), //STRING
      OverfillSpillProtection5: new FormControl('', Validators.maxLength(50)), //STRING
      OverfillSpillProtection6: new FormControl('', Validators.maxLength(50)), //STRING
      TankLeakDetection1: new FormControl('', Validators.maxLength(50)), //STRING
      TankLeakDetection2: new FormControl('', Validators.maxLength(50)), //STRING
      TankLeakDetection3: new FormControl('', Validators.maxLength(50)), //STRING
      TankLeakDetection4: new FormControl('', Validators.maxLength(50)), //STRING
      TankLeakDetection5: new FormControl('', Validators.maxLength(50)), //STRING
      TankLeakDetection6: new FormControl('', Validators.maxLength(50)), //STRING
      ASTDiking1: new FormControl('', Validators.maxLength(50)), //STRING
      ASTDiking2: new FormControl('', Validators.maxLength(50)), //STRING
      ASTDiking3: new FormControl('', Validators.maxLength(50)), //STRING
      ASTDiking4: new FormControl('', Validators.maxLength(50)), //STRING
      ASTDiking5: new FormControl('', Validators.maxLength(50)), //STRING
      ASTDiking6: new FormControl('', Validators.maxLength(50)), //STRING
      PipingConstruction1: new FormControl('', Validators.maxLength(50)), //STRING
      PipingConstruction2: new FormControl('', Validators.maxLength(50)), //STRING
      PipingConstruction3: new FormControl('', Validators.maxLength(50)), //STRING
      PipingConstruction4: new FormControl('', Validators.maxLength(50)), //STRING
      PipingConstruction5: new FormControl('', Validators.maxLength(50)), //STRING
      PipingConstruction6: new FormControl('', Validators.maxLength(50)), //STRING
      PipingLeakDetection1: new FormControl('', Validators.maxLength(50)), //STRING
      PipingLeakDetection2: new FormControl('', Validators.maxLength(50)), //STRING
      PipingLeakDetection3: new FormControl('', Validators.maxLength(50)), //STRING
      PipingLeakDetection4: new FormControl('', Validators.maxLength(50)), //STRING
      PipingLeakDetection5: new FormControl('', Validators.maxLength(50)), //STRING
      PipingLeakDetection6: new FormControl('', Validators.maxLength(50)), //STRING
      UserId: new FormControl('', [Validators.required, Validators.maxLength(128)]), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public activeMunicipality;
  public storageTankInventoryForm: FormGroup;
  public availableStorageTanks;

  ngOnInit() {
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        this.storageTankInventoryForm.disable();
        return;
      } else {
        this.storageTankInventoryForm.disable();
        this.activeMunicipality = response;
        this.getListStorageTankInventory(response);
      }
    });
  };

  public getListStorageTankInventory(data) {
    this.exposuresService.get(data.municipalityID, data.municipalityYear, 'GetListStorageTankInventoryDetails').subscribe((response: any) => {
      this.availableStorageTanks = response;
    });
  };

  public changeActiveTankLocation(activeTank) {
    this.storageTankInventoryForm.patchValue({
      'StorageTankInventoryDetails_yearId': activeTank.storageTankInventoryDetails_yearId,
      'StorageTankInventoryDetailsID': activeTank.storageTankInventoryDetailsID,
      'MunicipalityID': activeTank.municipalityID,
      'MunicipalityYear': activeTank.municipalityYear,
      'FacilityName': activeTank.facilityName,
      'FacilityAddress': activeTank.facilityAddress,
      'FacilityDate': activeTank.facilityDate,
      'FacilityId': activeTank.facilityId,
      'TankNo_1': activeTank.tankNo_1,
      'TankNo_2': activeTank.tankNo_2,
      'TankNo_3': activeTank.tankNo_3,
      'TankNo_4': activeTank.tankNo_4,
      'TankNo_5': activeTank.tankNo_5,
      'TankNo_6': activeTank.tankNo_6,
      'UST_AST1': activeTank.usT_AST1,
      'UST_AST2': activeTank.usT_AST2,
      'UST_AST3': activeTank.usT_AST3,
      'UST_AST4': activeTank.usT_AST4,
      'UST_AST5': activeTank.usT_AST5,
      'UST_AST6': activeTank.usT_AST6,
      'InstallDateYear1': activeTank.installDateYear1,
      'InstallDateYear2': activeTank.installDateYear2,
      'InstallDateYear3': activeTank.installDateYear3,
      'InstallDateYear4': activeTank.installDateYear4,
      'InstallDateYear5': activeTank.installDateYear5,
      'InstallDateYear6': activeTank.installDateYear6,
      'Capacity1': activeTank.capacity1,
      'Capacity2': activeTank.capacity2,
      'Capacity3': activeTank.capacity3,
      'Capacity4': activeTank.capacity4,
      'Capacity5': activeTank.capacity5,
      'Capacity6': activeTank.capacity6,
      'Contents1': activeTank.contents1,
      'Contents2': activeTank.contents2,
      'Contents3': activeTank.contents3,
      'Contents4': activeTank.contents4,
      'Contents5': activeTank.contents5,
      'Contents6': activeTank.contents6,
      'TankConstructionMaterial1': activeTank.tankConstructionMaterial1,
      'TankConstructionMaterial2': activeTank.tankConstructionMaterial2,
      'TankConstructionMaterial3': activeTank.tankConstructionMaterial3,
      'TankConstructionMaterial4': activeTank.tankConstructionMaterial4,
      'TankConstructionMaterial5': activeTank.tankConstructionMaterial5,
      'TankConstructionMaterial6': activeTank.tankConstructionMaterial6,
      'OverfillSpillProtection1': activeTank.overfillSpillProtection1,
      'OverfillSpillProtection2': activeTank.overfillSpillProtection2,
      'OverfillSpillProtection3': activeTank.overfillSpillProtection3,
      'OverfillSpillProtection4': activeTank.overfillSpillProtection4,
      'OverfillSpillProtection5': activeTank.overfillSpillProtection5,
      'OverfillSpillProtection6': activeTank.overfillSpillProtectio6,
      'TankLeakDetection1': activeTank.tankLeakDetection1,
      'TankLeakDetection2': activeTank.tankLeakDetection2,
      'TankLeakDetection3': activeTank.tankLeakDetection3,
      'TankLeakDetection4': activeTank.tankLeakDetection4,
      'TankLeakDetection5': activeTank.tankLeakDetection5,
      'TankLeakDetection6': activeTank.tankLeakDetection6,
      'ASTDiking1': activeTank.astDiking1,
      'ASTDiking2': activeTank.astDiking2,
      'ASTDiking3': activeTank.astDiking3,
      'ASTDiking4': activeTank.astDiking4,
      'ASTDiking5': activeTank.astDiking5,
      'ASTDiking6': activeTank.astDiking6,
      'PipingConstruction1': activeTank.pipingConstruction1,
      'PipingConstruction2': activeTank.pipingConstruction2,
      'PipingConstruction3': activeTank.pipingConstruction3,
      'PipingConstruction4': activeTank.pipingConstruction4,
      'PipingConstruction5': activeTank.pipingConstruction5,
      'PipingConstruction6': activeTank.pipingConstruction6,
      'PipingLeakDetection1': activeTank.pipingLeakDetection1,
      'PipingLeakDetection2': activeTank.pipingLeakDetection2,
      'PipingLeakDetection3': activeTank.pipingLeakDetection3,
      'PipingLeakDetection4': activeTank.pipingLeakDetection4,
      'PipingLeakDetection5': activeTank.pipingLeakDetection5,
      'PipingLeakDetection6': activeTank.pipingLeakDetection6,
      'UserId': activeTank.userId,
      'EffectiveDate': activeTank.effectiveDate
    });
    this.storageTankInventoryForm.enable();
  };

  public submitForm(form) {
    if (form.valid && form.value.StorageTankInventoryDetailsID == 0 && form.value.StorageTankInventoryDetails_yearId) {
      this.exposuresService.add(form.value, 'AddStorageTankInventoryDetails').subscribe((response: any) => {
        if (response.error == 0) {
          this.getListStorageTankInventory(this.activeMunicipality);
          this.notifier.notify("success", "Successfully created new record");
        } else {
          this.notifier.notify("error", response.message);
        }
      })
    } else if (form.valid) {
      this.exposuresService.update(form.value, 'UpdateStorageTankInventoryDetails').subscribe((response: any) => {
        if (response.error == 0) {
          this.getListStorageTankInventory(this.activeMunicipality);
          this.notifier.notify("success", "Successfully updated existing record");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  public openNewStorageTankLocationDialog() {
    const dialogRef = this.dialog.open(NewStorageTankLocationComponent, {
      data: {
        'MunicipalityID': this.activeMunicipality.municipalityID,
        'MunicipalityYear': this.activeMunicipality.municipalityYear,
        'UserId': this.authService.activeAccount.userID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exposuresService.add(result, 'AddStorageTankInventoryLocation').subscribe((response: any) => {
          if (response.error == 0) {
            this.getListStorageTankInventory(this.activeMunicipality);
            this.notifier.notify("success", "Successfully created new record");
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  public openUpdateStorageTankLocationDialog(id) {
    this.exposuresService.getDetail(id, 'GetDetailStorageTankInventoryLocation').subscribe((response: any) => {
      const dialogRef = this.dialog.open(UpdateStorageTankLocationComponent, {
        data: response
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.exposuresService.update(result, 'UpdateStorageTankInventoryLocation').subscribe((response: any) => {
            if (response.error == 0) {
              this.notifier.notify("success", "Successfully updated existing record");
            } else {
              this.notifier.notify("error", response.message);
            }
          });
        } else {
          console.log('The dialog was closed');
        }
      });
    });
  };
};
