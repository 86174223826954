//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-storage-tank-location',
  templateUrl: './new-storage-tank-location.component.html',
  styleUrls: ['./new-storage-tank-location.component.scss']
})

export class NewStorageTankLocationComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewStorageTankLocationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.newStorageTankLocationForm = new FormGroup({
      StorageTankLocationDetails_yearId: new FormControl(0), //INT
      StorageTankLocationDetailsID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      CompanyName: new FormControl('', Validators.maxLength(50)), //STRING
      Address: new FormControl('', Validators.maxLength(100)),
      USTLocation: new FormControl(0), //INT
      ASTLocation: new FormControl('', Validators.maxLength(50)), //STRING
      Contamination: new FormControl('', Validators.maxLength(50)), //STRING
      FacilityType: new FormControl('', Validators.maxLength(50)), //STRING
      FacilityTypeOther: new FormControl('', Validators.maxLength(50)), //STRING
      UserId: new FormControl('', [Validators.required, Validators.maxLength(128)]), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public newStorageTankLocationForm: FormGroup;

  ngOnInit() {
    this.newStorageTankLocationForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear,
      'UserId': this.data.UserId
    });
  };

  //Close modal and pass form data to controller
  submitNewStorageTankLocationForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
