//Import Modules
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";
import { FormValidator } from '../../shared/pipes/FormValidator';

//Import Services
import { RenewalApplicationsService } from '../../shared/services/renewal-applications/renewal-applications.service';
import { GeneralInfoService } from './service/general-info.service';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit {
  constructor(private renewalApplicationsService: RenewalApplicationsService, private generalInfoService: GeneralInfoService, private notifier: NotifierService) {
    this.generalForm = new FormGroup({
      RenewalApplicationID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      ApplicationDate: new FormControl('', Validators.required), //DATE
      CoverageEffectiveDate: new FormControl('', Validators.required), //DATE
      NameOfEntity: new FormControl('', Validators.required), //STRING
      ShortName: new FormControl('', Validators.required), //STRING
      ContactName: new FormControl('', Validators.required), //STRING
      ContactTitle: new FormControl('', Validators.required), //STRING
      ContactPhone: new FormControl('', Validators.required), //STRING
      ContactFax: new FormControl('', Validators.required), //STRING
      ContactEmail: new FormControl('', [Validators.required, Validators.email]), //STRING
      Address1: new FormControl('', Validators.required), //STRING
      Address2: new FormControl(''), //STRING
      City: new FormControl('', Validators.required), //STRING
      State: new FormControl({ value: 'NJ', disabled: true }, Validators.compose([Validators.minLength(2), Validators.maxLength(2), Validators.required, FormValidator.patternValidator(/^[A-Z]*$/, { isCapital: true })])), //STRING
      Zip: new FormControl('', Validators.compose([Validators.minLength(5), Validators.maxLength(5), Validators.required, FormValidator.patternValidator(/^[0-9]*$/, { isNumbersOnly: true })])), //STRING
      County: new FormControl('', Validators.required), //STRING)
      RiskManagerID: new FormControl(0, Validators.required) //INT
    });
  };

  public generalForm: FormGroup;
  public riskManagers;

  ngOnInit() {
    this.getListRiskManagersService();
    this.renewalApplicationsService.getMunicipality().subscribe((response: any) => {
      if (!response) {
        return;
      } else {
        this.getDetailRenewalApplicationService(response);
      }
    });
  };

  //Get risk managers & populate the dropdown box
  getListRiskManagersService() {
    this.generalInfoService.getListRiskManagers().subscribe((response: any) => {
      this.riskManagers = response;
    });
  };

  //Get general info application form for the following "municipality" and patch the form with the values
  getDetailRenewalApplicationService(data) {
    this.generalInfoService.getDetailGeneralInfo(data.municipalityID, data.municipalityYear).subscribe((response: any) => {
      if (response == null) {
        this.generalForm.reset();
      } else {
        this.generalForm.patchValue({
          'RenewalApplicationID': response.renewalApplicationID,
          'MunicipalityID': response.municipalityID,
          'ApplicationDate': response.applicationDate,
          'CoverageEffectiveDate': response.coverageEffectiveDate,
          'NameOfEntity': response.nameOfEntity,
          'ShortName': response.shortName,
          'ContactName': response.contactName,
          'ContactTitle': response.contactTitle,
          'ContactPhone': response.contactPhone,
          'ContactFax': response.contactFax,
          'ContactEmail': response.contactEmail,
          'Address1': response.address1,
          'Address2': response.address2,
          'City': response.city,
          'State': response.state,
          'Zip': response.zip,
          'County': response.county,
          'RiskManagerID': response.riskManagerID
        });
      }
    });
  };

  //Submit form to backend
  onSubmit(form) {
    if (form.valid) {
      this.generalInfoService.updateGeneral(form.getRawValue()).subscribe((response: any) => {
        if (response.error == 0) {
          this.notifier.notify("success", "Successfully updated existing record");
        } else {
          this.notifier.notify("error", response.message);
        }
      });
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };
};
