<div class="home-section-wrapper">
  <div class="row">
    <!--Freeze-updates-->
    <div class="col-md-12">
      <section class="mat-elevation-z8 white-box">
        <h1 class="title-box">Freeze Updates</h1>
        <!-- Filters -->
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="fill" class="field-full-width">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Client Name" #input >
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="field-full-width px-2" appearance="fill">
              <mat-label>Select year</mat-label>
              <mat-select [(ngModel)]="freezeUpdatesYear" (selectionChange)="changeFreezeYear()">
                <mat-option [value]="2025">2025</mat-option>
                <mat-option [value]="2024">2024</mat-option>
                <mat-option [value]="2023">2023</mat-option>
                <mat-option [value]="2022">2022</mat-option>
                <mat-option [value]="2021">2021</mat-option>
                <mat-option [value]="2020">2020</mat-option>
                <mat-option [value]="2019">2019</mat-option>
                <mat-option [value]="2018">2018</mat-option>
                <mat-option [value]="2017">2017</mat-option>
                <mat-option [value]="2016">2016</mat-option>
                <mat-option [value]="2015">2015</mat-option>
                <mat-option [value]="2014">2014</mat-option>
                <mat-option [value]="2013">2013</mat-option>
                <mat-option [value]="2012">2012</mat-option>
                <mat-option [value]="2011">2011</mat-option>
                <mat-option [value]="2010">2010</mat-option>
                <mat-option [value]="2009">2009</mat-option>
                <mat-option [value]="2008">2008</mat-option>
                <mat-option [value]="2007">2007</mat-option>
                <mat-option [value]="2006">2006</mat-option>
                <mat-option [value]="2005">2005</mat-option>
                <mat-option [value]="2004">2004</mat-option>
                <mat-option [value]="2003">2003</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- Results Table -->
        <table mat-table class="datatable" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="Municipality">
            <th mat-header-cell *matHeaderCellDef> Client </th>
            <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
          </ng-container>
          <ng-container matColumnDef="Locked">
            <th mat-header-cell *matHeaderCellDef> Locked </th>
            <td mat-cell *matCellDef="let element"><mat-slide-toggle ngDefaultControl [(ngModel)]="element.locked" (change)="freezeMunicipality(element)"></mat-slide-toggle></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="freezeUpdates"></tr>
          <tr mat-row *matRowDef="let row; columns: freezeUpdates;"></tr>
        </table>
      </section>
    </div>
  </div>
</div>
