<div class="general-info-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <form [formGroup]="generalForm" (ngSubmit)="onSubmit(generalForm)">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title">
            <h3>General Info</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <!-- ApplicationDate -->
          <mat-label>Date of Application <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ApplicationDate" [matDatepicker]="ApplicationDate">
            <mat-datepicker-toggle matSuffix [for]="ApplicationDate"></mat-datepicker-toggle>
            <mat-datepicker #ApplicationDate></mat-datepicker>
            <mat-error *ngIf="generalForm.controls['ApplicationDate'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--CoverageEffectiveDate -->
          <mat-label>Coverage Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="CoverageEffectiveDate" [matDatepicker]="CoverageEffectiveDate">
            <mat-datepicker-toggle matSuffix [for]="CoverageEffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #CoverageEffectiveDate></mat-datepicker>
            <mat-error *ngIf="generalForm.controls['CoverageEffectiveDate'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- NameOfEntity -->
          <mat-label>Name of Entity <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="NameOfEntity" placeholder="" />
            <mat-error *ngIf="generalForm.controls['NameOfEntity'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- Short Name -->
          <mat-label>Short Name <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ShortName" placeholder="" />
            <mat-error *ngIf="generalForm.controls['ShortName'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- ContactName -->
          <mat-label>Contact Name <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ContactName" placeholder="" />
            <mat-error *ngIf="generalForm.controls['ContactName'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- ContactTitle -->
          <mat-label>Contact Title <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ContactTitle" placeholder="" />
            <mat-error *ngIf="generalForm.controls['ContactTitle'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- Address1 -->
          <mat-label>Address 1 <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Address1" placeholder="" />
            <mat-error *ngIf="generalForm.controls['Address1'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- Address2 -->
          <mat-label>Address 2</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Address2" placeholder="" />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- City -->
          <mat-label>City <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="City" placeholder="" />
            <mat-error *ngIf="generalForm.controls['City'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- State -->
          <mat-label>State <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="State" placeholder="" />
            <mat-error *ngIf="generalForm.controls['State'].hasError('required')">This field is requried</mat-error>
            <mat-error *ngIf="generalForm.controls['State'].hasError('isCapital')">Must be 2 characters long, capital letters only</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- ZIP -->
          <mat-label>Zip <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="Zip" placeholder="" mask="00000" />
            <mat-error *ngIf="generalForm.controls['Zip'].hasError('required')">This field is required</mat-error>
            <mat-error *ngIf="generalForm.controls['Zip'].hasError('isNumbersOnly')">This field accepts numbers only</mat-error>
            <mat-error>Must be 5 characters long</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!-- County -->
          <mat-label>County <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="County">
              <mat-option value="Atlantic">Atlantic</mat-option>
              <mat-option value="Bergen">Bergen</mat-option>
              <mat-option value="Burlington">Burlington</mat-option>
              <mat-option value="Camden">Camden</mat-option>
              <mat-option value="Cape May">Cape May</mat-option>
              <mat-option value="Cumberland">Cumberland</mat-option>
              <mat-option value="Essex">Essex</mat-option>
              <mat-option value="Gloucester">Gloucester</mat-option>
              <mat-option value="Hudson">Hudson</mat-option>
              <mat-option value="Hunterdon">Hunterdon</mat-option>
              <mat-option value="Mercer">Mercer</mat-option>
              <mat-option value="Middlesex">Middlesex</mat-option>
              <mat-option value="Monmouth">Monmouth</mat-option>
              <mat-option value="Morris">Morris</mat-option>
              <mat-option value="Ocean">Ocean</mat-option>
              <mat-option value="Passaic">Passaic</mat-option>
              <mat-option value="Salem">Salem</mat-option>
              <mat-option value="Somerset">Somerset</mat-option>
              <mat-option value="Sussex">Sussex</mat-option>
              <mat-option value="Union">Union</mat-option>
              <mat-option value="Warren">Warren</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="generalForm.controls['County'].hasError('required')">This field is required</mat-error>
        </div>
        <div class="col-md-3">
          <!-- ContactPhone -->
          <mat-label>Phone <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ContactPhone" placeholder="" mask="(000) 000-0000" />
            <mat-error *ngIf="generalForm.controls['ContactPhone'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-label>Contact Fax <span class="field-required-symbol">*</span></mat-label>
          <!-- ContactFax -->
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ContactFax" placeholder="" mask="(000) 000-0000" />
            <mat-error *ngIf="generalForm.controls['ContactFax'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- ContactEmail -->
          <mat-label>Contact Email <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="ContactEmail" placeholder="" />
            <mat-error *ngIf="generalForm.controls['ContactEmail'].hasError('required')">This field is required</mat-error>
            <mat-error *ngIf="generalForm.controls['ContactEmail'].hasError('email')">Must be a valid email address</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- RiskManagerID -->
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-label>Risk Manager</mat-label>
            <mat-select formControlName="RiskManagerID">
              <mat-option *ngFor="let manager of riskManagers" [value]="manager.riskManagerID">{{manager.firstName}} {{manager.lastName}}</mat-option>
            </mat-select>
            <mat-error *ngIf="generalForm.controls['RiskManagerID'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- ActionButtons -->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="generalForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
