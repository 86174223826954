<div class="equipment-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Equipment</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="action-buttons">
          <button mat-raised-button type="button" color="primary" class="mb-2" [disabled]="!activeMunicipality" (click)="openNewDialog()">Add New Equipment</button>
          <button mat-raised-button type="button" color="primary" class="mb-2 ml-2" [disabled]="!activeMunicipality" (click)="exportExcel()">Export to Excel</button>
        </div>
      </div>
      <!-- Filter -->
      <div class="col-md-4">
        <mat-form-field class="field-full-width">
          <input matInput type="text" (keyup)="filterTable($event.target.value)" placeholder="Filter results">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="dataSource">
      <div class="col-md-12">
        

        <table class="datatable" mat-table [dataSource]="dataSource" matSort matSortActive="departmentName" matSortDirection="asc" [@.disabled]="true">
          <!-- Department -->
          <ng-container matColumnDef="departmentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
            <td mat-cell *matCellDef="let element"> {{element.departmentName}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- Year -->
          <ng-container matColumnDef="equipYear">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Year </th>
            <td mat-cell *matCellDef="let element"> {{element.equipYear}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- Model -->
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Model </th>
            <td mat-cell *matCellDef="let element"> {{element.model}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- Equip# -->
          <ng-container matColumnDef="equipNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Equip# </th>
            <td mat-cell *matCellDef="let element"> {{element.equipNumber}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <!-- Serial -->
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial# </th>
            <td mat-cell *matCellDef="let element"> {{element.serialNumber}} </td>
            <td mat-footer-cell *matFooterCellDef>Total</td>
          </ng-container>
          <!-- InsLimit -->
          <ng-container matColumnDef="insuranceLimit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> InsLimit </th>
            <td mat-cell *matCellDef="let element"> {{element.insuranceLimit | currency}} </td>
            <td mat-footer-cell *matFooterCellDef> {{calculateInsuranceLimit() | currency}} </td>
          </ng-container>
          <!-- Action -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="openUpdateDialog(element)">mode_edit</mat-icon>
              <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="openDeleteDialog(element)">delete</mat-icon>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </section>
</div>
