//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from "angular-notifier";
import { first } from 'rxjs/operators';

//Import Services
import { DepartmentsService } from '../../departments/services/departments.service';

@Component({
  selector: 'app-create-department',
  templateUrl: './create-department.component.html',
  styleUrls: ['./create-department.component.scss']
})
export class CreateDepartmentComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CreateDepartmentComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService, private departmentsService: DepartmentsService) {
    this.departmentsForm = new FormGroup({
      Department: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });
  };

  public departmentsForm: FormGroup;

  ngOnInit() {
  };

  //Close modal and pass form data to controller
  submitDepartmentForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  onNoClick() {
    this.dialogRef.close();
  };

}
