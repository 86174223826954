<div class="general-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>General Info</h3>
        </div>
      </div>
    </div>
    <form [formGroup]="generalForm" (ngSubmit)="submitForm(generalForm)">
      <div class="row">
        <div class="col-md-3">
          <!--PublicOfficialsElected-->
          <mat-label>1a. Number of Elected Public Official <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="PublicOfficialsElected" placeholder="" />
            <mat-error *ngIf="generalForm.controls['PublicOfficialsElected'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--PublicOfficialsAppointed-->
          <mat-label>1b. Number of Appointed Public Officials <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="PublicOfficialsAppointed" placeholder="" />
            <mat-error *ngIf="generalForm.controls['PublicOfficialsAppointed'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--PopulationNormal-->
          <mat-label>2a. Normal Population <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="PopulationNormal" placeholder="" />
            <mat-error *ngIf="generalForm.controls['PopulationNormal'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--PopulationSeasonal-->
          <mat-label>2b. Seasonal Population <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="PopulationSeasonal" placeholder="" />
            <mat-error *ngIf="generalForm.controls['PopulationSeasonal'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--EmployeeTotal-->
          <mat-label>3. Total Number of Employees <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="EmployeeTotal" placeholder="" />
            <mat-error *ngIf="generalForm.controls['EmployeeTotal'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--GrossPayrollTotal-->
          <mat-label>4. Total Gross Payroll <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="GrossPayrollTotal" placeholder="" />
            <mat-error *ngIf="generalForm.controls['GrossPayrollTotal'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--ContactorExpenditures-->
          <mat-label>5. Estimate of Independent Contractor Expenditure <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="ContactorExpenditures" placeholder="" />
            <mat-error *ngIf="generalForm.controls['ContactorExpenditures'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <!--QuasiMunicipalEntities-->
          <mat-label>6. Quasi Municipal Entities - Identify all such entities</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <textarea matInput formControlName="QuasiMunicipalEntities" placeholder="" rows="2"></textarea>
            <mat-error *ngIf="generalForm.controls['QuasiMunicipalEntities'].hasError('maxLength')">Character length for this field is 500</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <!--OperatingExpenditureEstimate-->
          <mat-label>7. Estimate of total Operating Expenditure for Upcoming Year <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="number" matInput formControlName="OperatingExpenditureEstimate" placeholder="" />
            <mat-error *ngIf="generalForm.controls['OperatingExpenditureEstimate'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!--ActionButtons-->
      <div class="form-action-buttons">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" mat-raised-button color="success" [disabled]="generalForm.disabled" class="submit-btn float-right">Save</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
