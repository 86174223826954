//Import Modules
import { Component, ChangeDetectorRef, OnInit, ViewChild, AfterViewInit, AfterViewChecked } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from "angular-notifier";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

// Import Services
import { FreezeUpdatesService } from "../../shared/services/freeze-updates/freeze-updates.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

export interface FreezeColumns {
  Municipality: string;
  Locked: boolean;
}

@Component({
  selector: 'app-freeze-updates',
  templateUrl: './freeze-updates.component.html',
  styleUrls: ['./freeze-updates.component.scss']
})
export class FreezeUpdatesComponent implements OnInit, AfterViewInit {
  constructor(private fb: FormBuilder, private freezeUpdatesService: FreezeUpdatesService, private notifier: NotifierService) { };

  @ViewChild(MatSort) sort: MatSort;

  //Freeze updates
  public freezeUpdates: string[] = ['Municipality', 'Locked'];
  public freezeUpdatesYear = new Date().getFullYear();

  public dataSource = new MatTableDataSource<FreezeColumns>();

  ngOnInit(): void {
    this.changeFreezeYear();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase(); //toLocaleLowerCase
  }

  //Change freeze year and filter available municipalities
  changeFreezeYear() {
    this.freezeUpdatesService.getFrozenMunicipalities(this.freezeUpdatesYear).subscribe((response: any) => {
      this.dataSource.data = response as FreezeColumns[];
    });
  };

  //Freeze municipality
  freezeMunicipality(municipality) {
    this.freezeUpdatesService.updateFreezeUpdate(municipality).subscribe((response: any) => {
      if (response.error == 0) {
        this.notifier.notify("success", "Updated");
      } else {
        this.notifier.notify("error", response.message);
      }
    });
  };
}
