//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-update-location-exposure-dialog',
  templateUrl: './update-location-exposure-dialog.component.html',
  styleUrls: ['./update-location-exposure-dialog.component.scss']
})
export class UpdateLocationExposureDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UpdateLocationExposureDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.updateLocationExposureForm = new FormGroup({
      CrimeLocationExposure_yearId: new FormControl(0), //INT
      CrimeLocationExposureID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0), //INT
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      Location: new FormControl(''), //STRING
      Cash: new FormControl(0), //INT
      RetailChecks: new FormControl(0, Validators.required), //INT
      CreditCardReceipts: new FormControl(0, Validators.required), //INT
      Safe: new FormControl(false, Validators.required), //Bool
      EffectiveDate: new FormControl('', Validators.required), //DATE
      UserId: new FormControl('', [Validators.required, Validators.maxLength(128)]) //STRING
    });
  };

  public updateLocationExposureForm: FormGroup;

  ngOnInit(): void {
    this.updateLocationExposureForm.patchValue({
      'CrimeLocationExposure_yearId': this.data.crimeLocationExposure_yearId,
      'CrimeLocationExposureID': this.data.crimeLocationExposureID,
      'MunicipalityID': this.data.municipalityID,
      'MunicipalityYear': this.data.municipalityYear,
      'Location': this.data.location,
      'Cash': this.data.cash,
      'RetailChecks': this.data.retailChecks,
      'CreditCardReceipts': this.data.creditCardReceipts,
      'Safe': this.data.creditCardReceipts,
      'EntryDate': this.data.entryDate,
      'EffectiveDate': this.data.effectiveDate,
      'UserId': this.data.userId
    });
  };

  //Close modal and pass form data to controller
  submitUpdateLocationExposureForm(form) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

}
