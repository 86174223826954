//Angular Modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/services/auth/helpers/auth.guard';
import { AccessGuard } from './shared/services/auth/helpers/access.guard';

//Components
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FreezeUpdatesComponent } from './dashboard/freeze-updates/freeze-updates.component';
import { GenerateYearComponent } from './dashboard/generate-year/generate-year.component';
import { GeneralInfoComponent } from './dashboard/general-info/general-info.component';
import { CrimeApplicationComponent } from './dashboard/exposures/crime/crime-application/crime-application.component';
import { GeneralComponent } from './dashboard/exposures/general/general.component';
import { AdditionalComponent } from './dashboard/exposures/additional/additional.component';
import { SpecificComponent } from './dashboard/exposures/specific/specific.component';
import { VehiclesComponent } from './dashboard/exposures/vehicles/vehicles.component';
import { PropertyComponent } from './dashboard/exposures/property/property.component';
import { CyberLiabilityComponent } from './dashboard/exposures/cyber-liability/cyber-liability.component';
import { NonOwnedAircraftLiabilityComponent } from './dashboard/exposures/non-owned-aircraft-liability/non-owned-aircraft-liability.component';
import { SiteEnvironmentalLiabilityComponent } from './dashboard/exposures/site-environmental-liability/site-environmental-liability.component';
import { UstLiabilityComponent } from './dashboard/exposures/ust-liability/ust-liability.component';
import { CrimePositionBondApplicationComponent } from './dashboard/exposures/crime/crime-position-bond-application/crime-position-bond-application.component';
import { StorageTankInventoryLocationComponent } from './dashboard/exposures/storage-tank-inventory-location/storage-tank-inventory-location.component';
import { StorageTankMarinaQuestionnaireComponent } from './dashboard/exposures/storage-tank-marina-questionnaire/storage-tank-marina-questionnaire.component';
import { EmployeeConcentrationComponent } from './dashboard/schedules/employee-concentration/employee-concentration.component';
import { LocationsPropertyValuesComponent } from './dashboard/schedules/locations-property-values/locations-property-values.component';
import { WatercraftComponent } from './dashboard/schedules/watercraft/watercraft.component';
import { FineArtsComponent } from './dashboard/schedules/fine-arts/fine-arts.component';
import { EquipmentComponent } from './dashboard/schedules/equipment/equipment.component';
import { VinComponent } from './dashboard/schedules/vin/vin.component';
import { VehicleComponent } from './dashboard/schedules/vehicle/vehicle.component';
import { PayrollComponent } from './dashboard/schedules/payroll/payroll.component';
import { UserProfileComponent } from './dashboard/user-profile/user-profile.component';
import { UsersComponent } from './dashboard/users/users.component';
import { LoginComponent } from './account/login/login.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { CrimePositionBondWorksheetComponent } from './dashboard/exposures/crime/crime-position-bond-worksheet/crime-position-bond-worksheet.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { ConfirmAccountComponent } from './account/confirm-account/confirm-account.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { CreatePasswordComponent } from './account/create-password/create-password.component';
import { ExposuresComponent } from './dashboard/exposures/exposures.component';
import { SchedulesComponent } from './dashboard/schedules/schedules.component';
import { RiskManagersComponent } from './dashboard/risk-managers/risk-managers.component';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { UploadsComponent } from './dashboard/uploads/uploads.component';
import { DepartmentsComponent } from './dashboard/departments/departments.component';
import { MunipalitiesComponent } from './dashboard/munipalities/munipalities.component';
import { TwoStepAuthComponent } from './account/two-step-auth/two-step-auth.component';

const routes: Routes =
  [
    { path: 'login', component: LoginComponent },
    { path: 'two-step-auth', component: TwoStepAuthComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'create-password', component: CreatePasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'confirm-account', component: ConfirmAccountComponent },
    {
      path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
      children: [
        { path: 'general-info', component: GeneralInfoComponent }, //OK
        {
          path: 'municipalities', component: MunipalitiesComponent, canActivate: [AccessGuard],
          data: {
            role: ['Admin', 'Manager', 'ReadOnly']
          }
        }, //OK
        {
          path: 'freeze-updates', component: FreezeUpdatesComponent, canActivate: [AccessGuard],
          data: {
            role: 'Admin'
          }
        },
        {
          path: 'generate-year', component: GenerateYearComponent, canActivate: [AccessGuard],
          data: {
            role: 'Admin'
          }
        }, //OK
        {
          path: 'risk-managers', component: RiskManagersComponent, canActivate: [AccessGuard],
          data: {
            role: 'Admin'
          }
        }, //OK
        {
          path: 'exposures', component: ExposuresComponent, children: [
            { path: 'crime/crime-application', component: CrimeApplicationComponent },
            { path: 'crime/crime-position-bond-application', component: CrimePositionBondApplicationComponent },
            { path: 'crime/crime-position-bond-worksheet', component: CrimePositionBondWorksheetComponent },
            { path: 'general', component: GeneralComponent },
            { path: 'additional', component: AdditionalComponent },
            { path: 'specific', component: SpecificComponent },
            { path: 'vehicles', component: VehiclesComponent },
            { path: 'property', component: PropertyComponent },
            { path: 'cyber-liability', component: CyberLiabilityComponent },
            { path: 'non-owned-aircraft-liability', component: NonOwnedAircraftLiabilityComponent },
            { path: 'site-environmental-liability', component: SiteEnvironmentalLiabilityComponent },
            { path: 'ust-liability', component: UstLiabilityComponent },
            { path: 'storage-tank-inventory-location', component: StorageTankInventoryLocationComponent },
            { path: 'storage-tank-marina-questionnaire', component: StorageTankMarinaQuestionnaireComponent },
          ]
        }, //OK
        {
          path: 'schedules', component: SchedulesComponent, canActivate: [AccessGuard],
          data: {
            role: ['Admin', 'Manager', 'Claims']
          }, children: [
            {
              path: 'employee-concentration', component: EmployeeConcentrationComponent, canActivate: [AccessGuard],
              data: {
                role: ['Admin', 'Manager', 'Claims']
              }
            },
            {
              path: 'watercraft', component: WatercraftComponent, canActivate: [AccessGuard],
              data: {
                role: ['Admin', 'Manager', 'Claims']
              }
            },
            {
              path: 'fine-arts', component: FineArtsComponent, canActivate: [AccessGuard],
              data: {
                role: ['Admin', 'Manager', 'Claims']
              }
            },
            {
              path: 'locations-property-values', component: LocationsPropertyValuesComponent, canActivate: [AccessGuard],
              data: {
                role: ['Admin', 'Manager', 'Claims']
              }
            },
            {
              path: 'equipment', component: EquipmentComponent, canActivate: [AccessGuard],
              data: {
                role: ['Admin', 'Manager', 'Claims']
              }
            },
            {
              path: 'vin', component: VinComponent, canActivate: [AccessGuard],
              data: {
                role: ['Admin', 'Manager', 'Claims']
              }
            },
            {
              path: 'payroll', component: PayrollComponent, canActivate: [AccessGuard],
              data: {
                role: ['Admin', 'Manager', 'Claims']
              }
            },
            {
              path: 'vehicle', component: VehicleComponent, canActivate: [AccessGuard],
              data: {
                role: ['Admin', 'Manager', 'Claims']
              }
            },
          ]
        }, //OK
        { path: 'user-profile', component: UserProfileComponent }, //OK
        { path: 'user-profile/change-password', component: ChangePasswordComponent }, //OK
        {
          path: 'users', component: UsersComponent, canActivate: [AccessGuard],
          data: {
            role: 'Admin'
          }
        }, //OK
        { path: 'reports', component: ReportsComponent }, //OK
        {
          path: 'uploads', component: UploadsComponent, canActivate: [AccessGuard],
          data: {
            role: 'Admin'
          }
        }, //OK
        {
          path: 'departments', component: DepartmentsComponent, canActivate: [AccessGuard],
          data: {
            role: ['Admin', 'Manager']
          }
        } //OK
      ]
    },
    { path: '**', redirectTo: 'login' }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
