//Import Modules
import { Component, ChangeDetectorRef, OnInit, ViewChild, AfterViewInit, AfterViewChecked, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from "angular-notifier";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from "rxjs/operators";

//Import Components
import { AddNewRiskManagerDialogComponent } from './dialog/add-new-risk-manager-dialog/add-new-risk-manager-dialog.component';
import { EditRiskManagerDialogComponent } from './dialog/edit-risk-manager-dialog/edit-risk-manager-dialog.component';
import { DeleteRiskManagerDialogComponent } from './dialog/delete-risk-manager-dialog/delete-risk-manager-dialog.component';

//Import Services
import { MunicipalitiesService } from '../../shared/services/municipalities/municipalities.service';

@Component({
  selector: 'app-risk-managers',
  templateUrl: './risk-managers.component.html',
  styleUrls: ['./risk-managers.component.scss']
})
export class RiskManagersComponent implements OnInit {
  constructor(private notifier: NotifierService, public dialog: MatDialog, private municipalitiesService: MunicipalitiesService) { };
  @ViewChild(MatSort) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.sort.disableClear = true;
    };
  };

  ngOnInit() {
    this.getRiskManagers();
  };

  public sort;
  public dataSource;
  public displayedColumns: string[] = ['firstName', 'lastName', 'city', 'address1', 'address2', 'company', 'action'];

  //Get table data
  public getRiskManagers() {
    this.municipalitiesService.getListRiskManagers().subscribe((response: any) => {
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.sort = this.sort;
    });
  };

  //Open new risk manager dialog
  public openNewRiskManagerDialog() {
    const dialogRef = this.dialog.open(AddNewRiskManagerDialogComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.municipalitiesService.addRiskManagers(result).subscribe((response: any) => {
          if (response.error == 0) {
            this.getRiskManagers();
            this.notifier.notify("success", response.message);
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  //Open edit risk manager dialog
  public openEditRiskManagerDialog(data) {
    const dialogRef = this.dialog.open(EditRiskManagerDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.municipalitiesService.updateRiskManagers(result).subscribe((response: any) => {
          if (response.error == 0) {
            this.getRiskManagers();
            this.notifier.notify("success", response.message);
          } else {
            this.notifier.notify("error", response.message);
          }
        });
      } else {
        console.log('The dialog was closed');
      }
    });
  };

  //Open delete risk manager dialog
  public openDeleteAccountDialog(data) {
    const dialogRef = this.dialog.open(DeleteRiskManagerDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.municipalitiesService.deleteRiskManagers(result).pipe(first()).subscribe({
          next: (response: any) => {
            if (response.error === 0) {
              this.getRiskManagers();
              this.notifier.notify('success', response.message);
            } else {
              this.notifier.notify('error', response.message);
            }
          },
          error: error => {
            this.notifier.notify('error', 'Something went wrong');
          }
        })
      } else {
        console.log('The dialog was closed');
      }
    });
  };
};
