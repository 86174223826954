<mat-dialog-content>
  <div class="dialog-title">
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-title">
          <h4 mat-dialog-title>Update Vehicle</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form">
    <form [formGroup]="updateVehicleForm" (ngSubmit)="submitUpdateVehicleForm(updateVehicleForm)">
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Department Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--DepartmentID -->
            <mat-label>Department <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <mat-select formControlName="DepartmentID">
                <mat-option *ngFor="let department of departments" [value]="department.departmentID">{{department.department}}</mat-option>
              </mat-select>
              <mat-error *ngIf="updateVehicleForm.controls['DepartmentID'].hasError('required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Vehicle Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--VehicleYear -->
            <mat-label>Vehicle Year <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="VehicleYear">
              <mat-error *ngIf="updateVehicleForm.controls['VehicleYear'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 4</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--Make -->
            <mat-label>Make <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Make">
              <mat-error *ngIf="updateVehicleForm.controls['Make'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--Model -->
            <mat-label>Model <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Model">
              <mat-error *ngIf="updateVehicleForm.controls['Model'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--VehicleNumber -->
            <mat-label>Vehicle Number</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="VehicleNumber">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--PlateNumber -->
            <mat-label>Plate Number</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="PlateNumber">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <!--VIN -->
            <mat-label>VIN <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="VIN">
              <mat-error *ngIf="updateVehicleForm.controls['VIN'].hasError('required')">This field is requried</mat-error>
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <!--VehicleDescription -->
            <mat-label>Vehicle Description</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="VehicleDescription">
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--CostNew -->
            <mat-label>Cost New</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="number" matInput formControlName="CostNew">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--ClassCode -->
            <mat-label>Class Code</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="ClassCode">
              <mat-error>Character length for this field is 50</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--GrossWeight -->
            <mat-label>Gross Weight</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="GrossWeight">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <!--CompColl -->
            <mat-checkbox formControlName="CompColl">CompColl</mat-checkbox>
          </div>
          <div class="col-md-4">
            <!--GarageLocation -->
            <mat-label>Garage Location</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="GarageLocation">
              <mat-error>Character length for this field is 100</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Financing Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Leased -->
            <mat-checkbox formControlName="Leased">Leased</mat-checkbox>
          </div>
          <div class="col-md-2">
            <!--LeaseAddIns -->
            <mat-checkbox formControlName="LeaseAddIns">Lease Add Ins</mat-checkbox>
          </div>
          <div class="col-md-2">
            <!--LeaseLossPayee -->
            <mat-checkbox formControlName="LeaseLossPayee">Lease Loss Payee</mat-checkbox>
          </div>
          <div class="col-md-3">
            <!--LeasingCompany -->
            <mat-label>Leasing Company</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="LeasingCompany">
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <!--Financed -->
            <mat-checkbox formControlName="Financed">Financed</mat-checkbox>
          </div>
          <div class="col-md-2">
            <!--FinanceLossPayee -->
            <mat-checkbox formControlName="FinanceLossPayee">Finance Loss Payee</mat-checkbox>
          </div>
          <div class="col-md-3">
            <!--FinanceCompany -->
            <mat-label>Finance Company</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="FinanceCompany">
              <mat-error>Character length for this field is 150</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-5">
            <!--Comments -->
            <mat-label>Comments</mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="Comments">
              <mat-error>Character length for this field is 250</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <div class="row">
          <div class="col-md-12">
            <div class="form-section-title">
              <h6>Entry Information</h6>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <!--EffectiveDate -->
            <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
            <mat-form-field class="field-full-width" appearance="fill">
              <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate">
              <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #EffectiveDate></mat-datepicker>
              <mat-error>This field is requried</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="dialog-action-buttons">
            <div mat-dialog-actions class="float-right">
              <button mat-button type="button" (click)="onNoClick()">Cancel</button>
              <button mat-button type="submit" mat-raised-button color="success" class="submit-btn">Update</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

