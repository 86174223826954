//Import Modules
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-new-pilot-dialog',
  templateUrl: './new-pilot-dialog.component.html',
  styleUrls: ['./new-pilot-dialog.component.scss']
})
export class NewPilotDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NewPilotDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private notifier: NotifierService) {
    this.newPilotForm = new FormGroup({
      QuestionnairePilot_yearId: new FormControl(0), //INT
      QuestionnairePilotID: new FormControl(0), //INT
      MunicipalityID: new FormControl(0),
      MunicipalityYear: new FormControl('', [Validators.minLength(4), Validators.maxLength(4)]), //STRING
      NameofPilot: new FormControl('', [Validators.maxLength(50)]), //STRING
      DOB: new FormControl('', [Validators.maxLength(15)]), //STRING
      Stud: new FormControl(false), //BOOL
      Pvt: new FormControl(false), //BOOL
      Coml: new FormControl(false), //BOOL
      ASEL: new FormControl(false), //BOOL
      AMEL: new FormControl(false), //BOOL
      Instrumt: new FormControl(false), //BOOL
      ATP: new FormControl(false), //BOOL
      Other: new FormControl('', Validators.maxLength(50)), //STRING
      DateLastPhysical: new FormControl('', Validators.maxLength(15)), //STRING
      Class: new FormControl('', Validators.maxLength(50)), //STRING
      Total: new FormControl(0), //INT
      RetractGear: new FormControl('', Validators.maxLength(50)), //STRING
      MultiEngine: new FormControl('', Validators.maxLength(50)), //STRING
      Last90days: new FormControl(0), //INT
      Last12Mos: new FormControl(0), //INT
      Last12MosPilot: new FormControl('', Validators.maxLength(50)), //STRING
      Next12MosPilot: new FormControl('', Validators.maxLength(50)), //STRING
      FAACrtNoPilot: new FormControl('', Validators.maxLength(50)), //STRING
      DateLastBiennialRev: new FormControl('', Validators.maxLength(15)), //STRING
      UserId: new FormControl('', Validators.maxLength(128)), //STRING
      EffectiveDate: new FormControl('', Validators.required) //DATE
    });
  };

  public newPilotForm: FormGroup;

  ngOnInit(): void {
    this.newPilotForm.patchValue({
      'MunicipalityID': this.data.MunicipalityID,
      'MunicipalityYear': this.data.MunicipalityYear,
      'UserId': this.data.UserId
    });
  };

  //Close modal and pass form data to controller
  submitNewPilotForm(form) {
    console.log(form);
    if (form.valid) {
      this.dialogRef.close(form.value);
    } else {
      this.notifier.notify("error", "Submitted form is invalid, please correct the form and try again");
    }
  };

  //Close modal without any changes
  onNoClick(): void {
    this.dialogRef.close();
  };

};
